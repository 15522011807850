import React, { Component } from "react";
import GrossSalesBreakdownPresenter from "./GrossSalesBreakdownPresenter";
import { findObjectUseCase } from "../../../../usecases/object";
import BaseListPage from "../../../../base/BaseListPage";
import { grossSaleCalculator } from "./utils/grossSaleCalculator";
import Table from "./Table/Table";
import Export from "../../../../components/Export";
import printJS from "print-js";
class GrossSalesBreakdownPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new GrossSalesBreakdownPresenter(
      this,
      findObjectUseCase()
    );
  }

  getCollectionName() {
    return "transactions";
  }

  exportToCSV() {
    const { objects } = this.props; // Assuming this is your data source
    const grossSaleData = grossSaleCalculator(objects); // Recalculate or use state

    // Define CSV headers
    const csvHeaders = "Brand,Location,Year,Gross Sales\n";

    // Map your data to the CSV format
    const csvRows = grossSaleData
      .map(
        (item) =>
          `${item.brand},${item.location},${item.years},${item.gross_sale}`
      )
      .join("\n");

    // Combine headers and rows
    const csvString = `${csvHeaders}${csvRows}`;

    // Convert the CSV string to a Blob
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });

    // Create a link and trigger download
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "gross_sales_breakdown.csv");
    document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(link);
  }

  printChart = (chart) => {
    setTimeout(() => {
      printJS({
        printable: chart,
        type: "html",
        header: `<div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem;">
                     <img src="/figaro.png" alt="Figaro" style="height: 50px;"/>
                     <h4 style="margin: 0 auto;">FIGARO COFFEE GROUP</h4>
                     <img src="/angels.png" alt="Angel's Pizza" style="height: 50px;"/>
                   </div>`,
        css: "./index.css",
        scanStyles: false,
        style: ".print-only-content { display: block; }",
      });
    }, 500);
  };

  render() {
    const transactions = this.props.objects;
    // console.log("transactions: ", transactions)
    const grossSaleData = grossSaleCalculator(transactions);
    const printId = "chart-container4";
    return (
      <>
        <div className="d-flex justify-content-between align-items-center mb-1">
          <h2 className=" fw-bold text-capitalize mt-4">
            <span style={{ color: "darkcolor" }}>Gross</span>

            <span style={{ color: "#83c14a" }}> Sales Breakdown</span>
          </h2>
          <Export
            exportCsv={() => this.exportToCSV()}
            exportPdf={() => this.printChart(printId)}
          />
        </div>
        <div ref={printId} id="chart-container4">
          <Table grossSaleData={grossSaleData} />
        </div>
      </>
    );
  }
}

export default GrossSalesBreakdownPage;

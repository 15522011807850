import React from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import BaseListPage from "../../../base/BaseListPage";
import DiscountDayTimePresenter from "./DiscountDayTimePresenter.js";
import { findObjectUseCase } from "../../../usecases/object";
import withRouter from "../../../withRouter.js";
import Export from "../../../components/Export/index.js";
import printChart from "../../../PrintChart.js";
import { Progress } from "nq-component";

class DiscountDayTimePage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new DiscountDayTimePresenter(this, findObjectUseCase());
    this.state = {
      objects: [],
      selectedDate: new Date().toISOString().split("T")[0],
      selectedStoreCode: "",
      store: [],
      storess: "",
      range: "",
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleStoreCodeChange = this.handleStoreCodeChange.bind(this);
  }

  getCollectionName() {
    return "transactions";
  }

  setStore(store) {
    console.log("storessss", store);
    this.setState({ store });
  }

  onChangeStore(where) {
    this.setState({ storess: where });
    this.presenter.onChangeStore(where);
  }

  getUniqueStoreCodes() {
    const storeCodes = this.state.objects.map((obj) =>
      // Remove "ANGELSPIZZA_" from the start and return the rest
      obj.store_code.replace("ANGELSPIZZA_", "")
    );

    return [...new Set(storeCodes)]; // Remove duplicates
  }

  // handleDateChange(event) {
  //   this.setState({ selectedDate: event.target.value });
  // }

  handleDateChange(event) {
    const selectedDate = event.target.value;
    const formattedDate = this.formatDate(selectedDate);
    this.setState({ selectedDate: event.target.value });
    // this.setState({ selectedDate });
    this.presenter.onChangeDate(formattedDate);
  }

  formatDate(dateString) {
    const date = new Date(dateString);

    // Start date (same day at 05:00:00.000)
    const startTime = new Date(date);
    startTime.setHours(5, 0, 0, 0);
    const formattedStartTime = this.formatDateToCustom(startTime);

    // End date (next day at 04:59:00.000)
    const endTime = new Date(date);
    endTime.setDate(endTime.getDate() + 1); // Move to the next day
    endTime.setHours(4, 59, 0, 0);
    const formattedEndTime = this.formatDateToCustom(endTime);

    return {
      tran_date: {
        $gte: formattedStartTime,
        $lte: formattedEndTime,
      },
    };
  }

  formatDateToCustom(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const milliseconds = String(date.getMilliseconds()).padStart(3, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
  }

  handleStoreCodeChange(event) {
    this.setState({ selectedStoreCode: event.target.value }); // Update the state when store code changes
  }

  filterTransactions(transactions) {
    const selectedDate = new Date(this.state.selectedDate);
    selectedDate.setHours(0, 0, 0, 0);

    return transactions
      .filter((obj) => obj.status === "C")
      .filter((transaction) => {
        const transactionDate = new Date(transaction.tran_date);
        transactionDate.setHours(0, 0, 0, 0);

        const selectedDateMatch =
          transactionDate.getTime() === selectedDate.getTime();
        const storeCodeMatch = this.state.selectedStoreCode
          ? transaction.store_code.replace("ANGELSPIZZA_", "") ===
            this.state.selectedStoreCode
          : true;

        return selectedDateMatch && storeCodeMatch;
      });
  }

  countDiscountAmountPerHour = (transactions) => {
    const discountAmounts = new Array(24).fill(0);

    transactions.forEach((transaction) => {
      const hour = new Date(transaction.createdAt).getHours();
      const discountAmount = Number(transaction.discount_amount) || 0; // Replace with your actual discount amount property
      discountAmounts[hour] += discountAmount;
    });

    return discountAmounts;
  };

  getChartData() {
    const filteredTransactions = this.filterTransactions(this.state.objects);
    const discountAmountsPerHour =
      this.countDiscountAmountPerHour(filteredTransactions);
    const labels = Array.from({ length: 24 }, (_, i) => {
      const hour = (5 + i) % 24;
      return `${hour}:00`;
    });

    return {
      labels: labels,
      datasets: [
        // ... existing datasets for Dine-in, GrabFood, and Take-out
        {
          label: "Discount Amount",
          data: discountAmountsPerHour,
          borderColor: "rgb(255, 99, 132)", // Choose a color that suits your chart
          backgroundColor: "rgba(255, 99, 132, 0.5)", // Adjust the color to be consistent with your design
          fill: true,
        },
      ],
    };
  }

  getChartOptions() {
    return {
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: "Total Transactions vs Time of Day (Hours)",
          },
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      },
    };
  }

  convertToCSV(objArray) {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str =
      `${Object.keys(array[0])
        .map((value) => `"${value}"`)
        .join(",")}` + "\r\n";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line !== "") line += ",";
        line += `"${array[i][index]}"`;
      }
      str += line + "\r\n";
    }
    return str;
  }

  exportToCSV = () => {
    const { objects, selectedStoreCode, selectedDate } = this.state;

    // Filter data based on the selected store and date
    const filteredTransactions = this.filterTransactions(objects);

    // Create an object to store aggregated data for each hour
    const aggregatedData = {};

    // Loop through filtered transactions and aggregate data by hour
    filteredTransactions.forEach((transaction) => {
      const transactionDate = new Date(transaction.createdAt);
      const hour = transactionDate.getHours();

      // Initialize or update aggregated data for the hour
      if (!aggregatedData[hour]) {
        aggregatedData[hour] = {
          Brand: "Angels Pizza", // Replace with your actual brand data
          Store: transaction.store_code.split("_")[1],
          Date: selectedDate,
        };
      }
      aggregatedData[hour][`${hour}:00`] =
        (aggregatedData[hour][`${hour}:00`] || 0) +
        (Number(transaction.discount_amount) || 0);
    });

    // Define CSV headers dynamically based on available hours
    const headers = [
      "Brand",
      "Store",
      "Date",
      ...Array.from({ length: 24 }, (_, i) => `${i}:00`),
    ];
    let csvContent = [headers.join(",")];

    // Loop over each aggregated hour data and format the row
    Object.values(aggregatedData).forEach((rowData) => {
      const row = headers.map((header) => rowData[header] || 0);
      csvContent.push(row.join(","));
    });

    // Convert the rows to a single string
    csvContent = csvContent.join("\n");

    // Create a Blob and trigger the download
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `DiscountDayTime_${selectedDate}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // In your render method, add a button to trigger this function
  // <button onClick={this.exportToCSV}>Export to CSV</button>

  render() {
    const chartData = this.getChartData();
    const chartOptions = this.getChartOptions();
    const uniqueStoreCodes = this.getUniqueStoreCodes();
    const chartId = "chart-container18";
    return (
      <>
        <div className="d-flex justify-content-between align-items-center mb-1 mt-4">
          <h2 className="fw-bold text-capitalize">
            <span style={{ color: "black" }}>Discount</span>
            <span style={{ color: "#83c14a" }}> Value</span>
            <span style={{ color: "black" }}> vs.</span>
            <span style={{ color: "#c5da55" }}> Time of Day</span>
          </h2>
          <Export
            exportCsv={() => this.exportToCSV()}
            exportPdf={() => printChart(chartId)}
          />
        </div>

        <div className="row mb-2">
          <div className="col-sm-2">
            <input
              className="form-control"
              type="date"
              // value={this.state.selectedDate}
              onChange={this.handleDateChange}
            />
          </div>
          <div className="col-sm-3">
            <select
              className="form-select"
              // value={this.state.selectedStoreCode}
              onChange={(e) => this.onChangeStore(e.target.value)}
            >
              <option value="">Select Store</option>
              <option value="AllStore">All Store</option>
              {this.state.store.map((code, index) => (
                <option key={index} value={code.name}>
                  {code.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {this.state.objects && this.state.objects.length > 0 ? (
          <div ref={this.chartRef1} id="chart-container18">
            <div className="card">
              <Line data={chartData} options={chartOptions} />
            </div>
            <div className="print-only-content">
              <h2>Report Details</h2>
              <div className=" print-only-content">
                <div className="">
                  <h3>Brand: Angels Pizza</h3>
                  <h3>Store: {this.state.storess}</h3>
                  <h3>Date: {this.state.selectedDate}</h3>
                </div>
              </div>
              <div className="" style={{ fontSize: "20px" }}>
                <table className="table text-center">
                  <thead>
                    <tr className="d-flex">
                      <th>Time</th>
                      <th className="ms-5">Discount Value</th>{" "}
                    </tr>
                  </thead>
                  <tbody>
                    {chartData.labels.map((label, index) => (
                      <tr className="d-flex" key={label}>
                        <td className="ms-5">{label} ------------ </td>
                        <td className="ms-5">
                          {chartData.datasets[0].data[index]}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : this.state.storess ? (
          <div className="text-center mt-5">
            <Progress />
            <h6>Processing... Please wait.</h6>
          </div>
        ) : (
          <div className="text-center align-items-center mt-4">
            <img
              src="./choosefirst.png"
              style={{ height: "200px", width: "200px" }}
            />
            <h4>To proceed, please select a store first</h4>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(DiscountDayTimePage);

// import React from "react";
// import BaseListPage from "../../base/BaseListPage";
// import withRouter from "../../withRouter";
// import { countObjectUseCase, findObjectUseCase } from "../../usecases/object";
// import NavBar from "../../components/navbar";
// import schemaDashboard from "./schemaDashboard.json";
// import CustomerPresenter from "./CustomerPresenter";
// import CustomerCountPage from "./chart/customer-count/CustomerCountPage";
// import SalesPerSegmentPage from "./chart/sales-per-segment/SalesPerSegmentPage";
// import CustomerBreakdownPage from "./chart/customer-breakdown/CustomerBreakdownPage";
// import Top5ProductsBuyPage from "./chart/top5-products-buy/Top5ProductsBuyPage";
// import CustomerSalesPerStorePage from "./chart/customer-sales-per-store/CustomerSalesPerStorePage";

// class CustomerPage extends BaseListPage {
//   constructor(props) {
//     super(props);
//     this.presenter = new CustomerPresenter(
//       this,
//       findObjectUseCase(),
//       countObjectUseCase()
//     );
//     this.state = {
//       objects: [],
//       selected: [],
//       progress: true,
//       count: 0,
//       where: {},
//       selectedOption: "",
//       store: [],
//       storess: "",
//       range: "",
//     };
//   }

//   getCollectionName() {
//     return "transactions";
//   }

//   onChangeDate(where) {
//     this.presenter.onChangeDate(where);
//     this.setState({ range: where });
//   }

//   onChangeStore(where) {
//     this.presenter.onChangeStore(where);
//     this.setState({ storess: where });
//   }

//   getValueForDashboardObject(object) {
//     switch (object.label) {
//       case "Total Item Sold":
//         return this.presenter.calculateTotalItemSoldSalesInfo();
//       case "Total Transaction":
//         return this.presenter.calculateTotalTransactionInfo();
//       case "Total Guest":
//         return this.presenter.calculateTotalGuestInfo();
//       case "Discount Cost":
//         return this.presenter.calculateDiscountCost();
//       default:
//         return { value: 0, percentage: 0 };
//     }
//   }

//   handleCountClick(nav) {
//     this.navigateTo(nav);
//   }

//   filterByName(name) {
//     return this.state.objects.filter((obj) => obj.cust_name === name);
//   }

//   handleSelectChange = (event) => {
//     this.setState({ selectedOption: event.target.value });
//   };

//   setStore(store) {
//     // console.log("Store", store);
//     this.setState({ store });
//   }

//   render() {
//     const { objects, store } = this.state;
//     // const filteredObjects = this.filterByName("FAUSTINO , GHIE ");
//     // // console.log("Filtered Customer Data", filteredObjects);

//     return (
//       <>
//         <NavBar />
//         <div className="overflow-auto">
//           <div className="p-3 p-lg-4">
//             <div className="d-flex justify-content-between align-items-center">
//               <h1
//                 className="fw-bold mt-3 text-capitalize"
//                 style={{ color: "#83c14a" }}
//               >
//                 Customer Analytics
//               </h1>
//             </div>

//             <div className="row mt-1 g-3">
//               {schemaDashboard.map((card) => (
//                 <>
//                   {/* For medium and larger screens (≥768px), use 4-column width. For smaller screens, use 6-column width. */}
//                   <div className="col-md-4 col-6">
//                     <div
//                       className="card p-2"
//                       style={{ backgroundColor: card.bg }}
//                     >
//                       <h6 className="fw-bold">{card.label}</h6>
//                       <p style={{ fontSize: "14px", color: "#6e93d3" }}>
//                         {card.spend}
//                       </p>
//                       <p style={{ fontSize: "14px", color: "#51d1f6" }}>
//                         {card.transaction}
//                       </p>
//                       <p style={{ fontSize: "14px", color: "#d4bb58" }}>
//                         {card.Recency}
//                       </p>
//                     </div>
//                   </div>
//                 </>
//               ))}
//             </div>

//             <CustomerCountPage
//               transactions={objects}
//               onChangeDate={this.onChangeDate.bind(this)}
//               onChangeStore={this.onChangeStore.bind(this)}
//               dateField="tran_date"
//               store={store}
//               storess={this.state.storess}
//               range={this.state.range}
//             />
//             <SalesPerSegmentPage
//               transactions={objects}
//               onChangeDate={this.onChangeDate.bind(this)}
//               onChangeStore={this.onChangeStore.bind(this)}
//               dateField="tran_date"
//               store={store}
//               storess={this.state.storess}
//               range={this.state.range}
//             />
//             <div className="mt-4 col-sm-4">
//               {" "}
//               <select
//                 className="form-select"
//                 value={this.state.selectedOption}
//                 onChange={this.handleSelectChange}
//               >
//                 <option value="">Select Option</option>
//                 <option value="top5">Top 5 Products that Customers Buy</option>
//                 <option value="breakdown">Customer Breakdown</option>
//                 <option value="customer">Customer Sales Per Store</option>
//               </select>
//             </div>

//             {this.state.selectedOption === "top5" && (
//               <Top5ProductsBuyPage transactions={objects} />
//             )}
//             {this.state.selectedOption === "breakdown" && (
//               <CustomerBreakdownPage transactions={objects} />
//             )}
//             {this.state.selectedOption === "customer" && (
//               <CustomerSalesPerStorePage transactions={objects} />
//             )}
//           </div>
//         </div>
//       </>
//     );
//   }
// }

// export default withRouter(CustomerPage);

import React from "react";
import BaseListPage from "../../base/BaseListPage";
import withRouter from "../../withRouter";
import { countObjectUseCase, findObjectUseCase } from "../../usecases/object";
import NavBar from "../../components/navbar";
import schemaDashboard from "./schemaDashboard.json";
import CustomerPresenter from "./CustomerPresenter";
import CustomerCountPage from "./chart/customer-count/CustomerCountPage";
import SalesPerSegmentPage from "./chart/sales-per-segment/SalesPerSegmentPage";
import CustomerBreakdownPage from "./chart/customer-breakdown/CustomerBreakdownPage";
import Top5ProductsBuyPage from "./chart/top5-products-buy/Top5ProductsBuyPage";
import CustomerSalesPerStorePage from "./chart/customer-sales-per-store/CustomerSalesPerStorePage";

class CustomerPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new CustomerPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase()
    );
    this.state = {
      objects: [],
      selected: [],
      progress: true,
      count: 0,
      where: {},
      selectedOption: "",
      store: [],
      storess: "",
      range: "",
      loading: false, // Add loading state to manage progress
    };
  }

  getCollectionName() {
    return "transactions";
  }

  onChangeDate(where) {
    this.presenter.onChangeDate(where);
    this.setState({ range: where });
  }

  onChangeStore(where) {
    this.presenter.onChangeStore(where);
    this.setState({ storess: where });
  }

  getValueForDashboardObject(object) {
    switch (object.label) {
      case "Total Item Sold":
        return this.presenter.calculateTotalItemSoldSalesInfo();
      case "Total Transaction":
        return this.presenter.calculateTotalTransactionInfo();
      case "Total Guest":
        return this.presenter.calculateTotalGuestInfo();
      case "Discount Cost":
        return this.presenter.calculateDiscountCost();
      default:
        return { value: 0, percentage: 0 };
    }
  }

  handleCountClick(nav) {
    this.navigateTo(nav);
  }

  // Show loading spinner after selecting an option
  handleSelectChange = (event) => {
    this.setState({ selectedOption: event.target.value, loading: true });

    // Simulate a delay for the data to load (like fetching data from API)
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1000); // Adjust timeout based on actual data loading time
  };

  setStore(store) {
    this.setState({ store });
  }

  render() {
    const { objects, store, loading } = this.state;

    return (
      <>
        <NavBar />
        <div className="overflow-auto">
          <div className="p-3 p-lg-4">
            <div className="d-flex justify-content-between align-items-center">
              <h1
                className="fw-bold mt-3 text-capitalize"
                style={{ color: "#83c14a" }}
              >
                Customer Analytics
              </h1>
            </div>

            <div className="row mt-1 g-3">
              {schemaDashboard.map((card) => (
                <div className="col-md-4 col-6" key={card.label}>
                  <div
                    className="card p-2"
                    style={{ backgroundColor: card.bg }}
                  >
                    <h6 className="fw-bold">{card.label}</h6>
                    <p style={{ fontSize: "14px", color: "#6e93d3" }}>
                      {card.spend}
                    </p>
                    <p style={{ fontSize: "14px", color: "#51d1f6" }}>
                      {card.transaction}
                    </p>
                    <p style={{ fontSize: "14px", color: "#d4bb58" }}>
                      {card.Recency}
                    </p>
                  </div>
                </div>
              ))}
            </div>

            <CustomerCountPage
              transactions={objects}
              onChangeDate={this.onChangeDate.bind(this)}
              onChangeStore={this.onChangeStore.bind(this)}
              dateField="tran_date"
              store={store}
              storess={this.state.storess}
              range={this.state.range}
            />
            <SalesPerSegmentPage
              transactions={objects}
              onChangeDate={this.onChangeDate.bind(this)}
              onChangeStore={this.onChangeStore.bind(this)}
              dateField="tran_date"
              store={store}
              storess={this.state.storess}
              range={this.state.range}
            />

            {/* Dropdown to select options */}
            <div className="mt-4 col-sm-4">
              <select
                className="form-select"
                value={this.state.selectedOption}
                onChange={this.handleSelectChange}
              >
                <option value="">Select Option</option>
                <option value="top5">Top 5 Products that Customers Buy</option>
                <option value="breakdown">Customer Breakdown</option>
                <option value="customer">Customer Sales Per Store</option>
              </select>
            </div>

            {/* Show loading spinner or actual content based on the loading state */}
            {loading ? (
              <div className="text-center mt-4">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <>
                {this.state.selectedOption === "top5" && (
                  <Top5ProductsBuyPage transactions={objects} />
                )}
                {this.state.selectedOption === "breakdown" && (
                  <CustomerBreakdownPage transactions={objects} />
                )}
                {this.state.selectedOption === "customer" && (
                  <CustomerSalesPerStorePage transactions={objects} />
                )}
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(CustomerPage);

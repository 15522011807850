import React from "react";
import BaseListPage from "../../base/BaseListPage";
import withRouter from "../../withRouter";
import { countObjectUseCase, findObjectUseCase } from "../../usecases/object";
import NavBar from "../../components/navbar";
import schemaDashboard from "./schemaDashboard.json";
// import Count from "../dashboard/components/Count";
import DateRange from "../../components/DateRange";
import ReportDashPresenter from "./ReportDashPresenter";
import Count from "./components/Count";
import NavBar2 from "../../components/NavBar2";
import printComponent from "../../printComponent";
import Papa from "papaparse";
import ExportPdfDash from "../../components/ExportPdfDash";
import ExportPdfDash2 from "../../components/ExportPdfDash2";
import printChart from "../../PrintChart";
import { Progress } from "nq-component";
import DateRangeReport from "../../components/DateRangeReport";

class ReportDashPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new ReportDashPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase()
    );
    this.state = {
      objects: [],
      selected: [],
      progress: true,
      count: 0,
      where: {},
      trans: null,
      startDate: "",
      endDate: "",
      range: "",
      storess: "",
      store: [],
    };
    this.certRef = React.createRef();
  }

  getCollectionName() {
    return "transactions";
  }

  onChangeStore(where) {
    this.setState({ storess: where });
    this.presenter.onChangeStore(where);
  }

  setStore(store) {
    this.setState({ store });
  }

  // onChangeDate(where) {
  //   this.presenter.onChangeDate(where);
  //   console.log("Date", where);
  //   const startDate = where.createdAt?.$gte
  //     ? new Date(where.createdAt.$gte).toLocaleDateString()
  //     : "";
  //   const endDate = where.createdAt?.$lte
  //     ? new Date(where.createdAt.$lte).toLocaleDateString()
  //     : "";

  //   this.setState({
  //     startDate: startDate,
  //     endDate: endDate,
  //   });
  // }

  onChangeDate(where) {
    // Assuming where.createdAt.$gte and where.createdAt.$lte are the dates selected by the user
    const startDate = where.createdAt?.$gte
      ? new Date(where.createdAt.$gte)
      : new Date();
    const endDate = where.createdAt?.$lte
      ? new Date(where.createdAt.$lte)
      : new Date();

    // If startDate and endDate are the same, adjust endDate to include the entire day
    if (startDate.toDateString() === endDate.toDateString()) {
      endDate.setHours(23, 59, 59, 999); // Set to end of the day
    }

    // Convert back to your desired format if necessary
    const formattedStartDate = startDate.toLocaleDateString();
    const formattedEndDate = endDate.toLocaleDateString();

    // Assuming this.presenter.onChangeDate() expects the date range in a specific format
    const adjustedWhere = {
      ...where,
      createdAt: {
        $gte: startDate,
        $lte: endDate,
      },
    };

    // Update the presenter and state with the adjusted dates
    this.presenter.onChangeDate(where);
    this.setState({ range: where });

    this.setState({
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    });
  }

  getValueForDashboardObject(object) {
    switch (object.label) {
      case "Delivery":
        return this.presenter.calculateDeliveryInfo();
      case "Dine In":
        return this.presenter.calculateDineInfo();
      case "Take Out":
        return this.presenter.calculateTakeOutInfo();
      case "Grab Food":
        return this.presenter.calculateGrabFoodInfo();
      case "Pick Up":
        return this.presenter.calculatePickUpInfo();
      case "Del Time":
        return this.presenter.calculateDelTimeInfo();
      case "Drive Thru":
        return this.presenter.calculateDriveInfo();
      case "FoodPanda":
        return this.presenter.calculatePandaInfo();
      default:
        return { value: 0, percentage: 0 };
    }
  }

  handleCountClick(nav) {
    this.navigateTo(nav);
  }

  onClickPrint() {
    // printComponent(this.certRef.current, "certificate").then(() => {});
    this.setStatePromise()
      .then(() => printComponent(this.certRef.current, "certificate"))
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        alert(error);
      });
  }

  onClickExport() {
    // Define the static data for the row
    const filteredObj = this.state.objects.filter((obj) => obj.status === "C");
    const gross_sales = filteredObj
      .map(
        (object) =>
          Number(object.total) +
          Number(object.discount_amount) -
          Number(object.total_tax)
      )
      .reduce((acc, curr) => acc + curr, 0);
    const totalDiscountAmount = filteredObj.reduce(
      (acc, object) => acc + Number(object.discount_amount),
      0
    );
    const totalPretax = filteredObj.reduce(
      (acc, object) => acc + Number(object.total_pretax),
      0
    );
    const totalTax = filteredObj.reduce(
      (acc, object) => acc + Number(object.total_tax),
      0
    );
    const totalTaxFree = filteredObj.reduce(
      (acc, object) => acc + Number(object.total_taxfree),
      0
    );

    const staticDataRow = {
      gross_sales: gross_sales,
      net_sales: gross_sales - totalDiscountAmount,
      pretax: totalPretax,
      tax: totalTax,
      tax_free: totalTaxFree,
      tax_free_amount: "",
      discount_total: totalDiscountAmount,
    };

    // Map your schema to the desired format
    const data = schemaDashboard.map((object, index) => {
      const { value, percentage } = this.getValueForDashboardObject(object);
      // Only add the static data to the first object
      const additionalData = index === 0 ? staticDataRow : {};
      return {
        trans_type: object.label,
        TotalSales: value,
        start_date: this.state.startDate,
        end_date: this.state.endDate,
        "": "",

        ...additionalData,
      };
    });

    // Unparse the data array into CSV format
    const csv = Papa.unparse(data);

    // Create a Blob with the CSV data
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    // Create a link and trigger the download
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "dashboard_data.csv");
    link.style.visibility = "hidden";

    // Append the link, trigger the click, and then remove the link
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  render() {
    const objects = this.state.objects;
    const data = schemaDashboard.map((object) => {
      const { value, percentage } = this.getValueForDashboardObject(object);

      return {
        trans_type: object.label,
        TotalSales: value,
      };
    });
    const chartId = "chart-container30";

    return (
      <>
        <div className="d-none">
          <div ref={this.certRef}>
            <ExportPdfDash2 data={data} />
          </div>
        </div>
        <NavBar2
          action={() => (
            <div className="dropdown dropstart d-inline-block me-2">
              <i
                role="button"
                data-bs-toggle="dropdown"
                className="bi bi-three-dots-vertical"
              ></i>
              <div className="dropdown-menu fs-xs">
                <button
                  onClick={this.onClickExport.bind(this)}
                  className="dropdown-item py-3"
                >
                  <i className="bi bi-arrow-up-square pe-2" />
                  Export CSV Data
                </button>
                <button
                  onClick={() => printChart(chartId)}
                  className="dropdown-item py-3"
                >
                  <i className="bi bi-arrow-up-square pe-2" />
                  Export to PDF
                </button>
              </div>
            </div>
          )}
        />
        <div className="overflow-auto">
          <div className="p-3 p-lg-4">
            <div className="d-flex justify-content-between align-items-center">
              <h1
                className="fw-bold mt-3 text-capitalize"
                style={{ color: "#83c14a" }}
              >
                Sales Dashboard
              </h1>
            </div>
            <div className="row mb-2">
              <div className="col-sm-4" style={{ maxWidth: "max-content" }}>
                <DateRangeReport
                  onChange={this.onChangeDate.bind(this)}
                  field="tran_date"
                />
              </div>

              <div className="col-sm-3">
                <select
                  className="form-select"
                  // value={this.state.selectedStoreCode}
                  onChange={(e) => this.onChangeStore(e.target.value)}
                  disabled={this.state.range ? false : true}
                >
                  <option value="">Select Store</option>
                  <option value="AllStore">All Store</option>
                  {this.state.store.map((code, index) => (
                    <option key={index} value={code.name}>
                      {code.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {objects?.length > 0 ? (
              <div className="row mt-1 g-3" id="chart-container30">
                {schemaDashboard.map((object, index) => {
                  const { value, percentage } = this.getValueForDashboardObject(
                    object,
                    objects
                  );

                  const navigationTarget = object.navigationTarget;
                  return (
                    <div key={index} className="col-6 col-md-3">
                      <Count
                        collection={object.collection}
                        icon={object.icon}
                        where={object.where}
                        val={value}
                        label={object.label}
                        labelAction={object.labelAction}
                        barangayValue={this.state.barangayValue}
                        object={object}
                        percentage={percentage}
                        bg={object.bg}
                        onClick={() => this.handleCountClick(navigationTarget)}
                      />
                    </div>
                  );
                })}
              </div>
            ) : this.state.range ? (
              <div className="text-center mt-5">
                <Progress />
                <h6>Processing... Please wait.</h6>
              </div>
            ) : (
              <div className="text-center align-items-center mt-4">
                <img
                  src="./choosefirst.png"
                  style={{ height: "200px", width: "200px" }}
                />
                <h4>To proceed, please select a Date Range first</h4>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(ReportDashPage);

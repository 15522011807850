// const getCustomerCategories = (transactions, selectedStore) => {
//   console.log("bilang", transactions);
//   console.log("stores", selectedStore);

//   const oneYearAgo = new Date();
//   oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

//   const thirtyDaysAgo = new Date();
//   thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

//   const ninetyDaysAgo = new Date();
//   ninetyDaysAgo.setDate(ninetyDaysAgo.getDate() - 90);

//   const currentDate = new Date();
//   const customerMap = new Map();

//   transactions.forEach((transaction) => {
//     const storeCode = transaction.store_code.replace("ANGELSPIZZA_", "");
//     if (selectedStore !== "All" && storeCode !== selectedStore) {
//       return;
//     }

//     const tranDate = new Date(transaction.tran_date);

//     const transactionDetail = {
//       date: tranDate.toLocaleDateString(),
//       totalSpend: parseFloat(transaction.total),
//     };

//     if (tranDate >= oneYearAgo) {
//       let customerKey;

//       if (transaction.cust_name === "-") {
//         // Use a unique key for anonymous customers
//         customerKey = `${transaction.cust_phone}_${tranDate.getTime()}`;
//       } else {
//         // Group customers by phone and name
//         customerKey = `${transaction.cust_phone}_${transaction.cust_name}`;
//       }

//       const existingCustomer = customerMap.get(customerKey);
//       const customer = existingCustomer || {
//         custPhone: transaction.cust_phone,
//         custName: transaction.cust_name,
//         totalSpend: 0,
//         purchaseCount: 0,
//         lastPurchaseDate: tranDate,
//         custEmail: transaction.cust_email,
//         custMobile: transaction.cust_mobile,
//         daysSinceLastPurchase: Math.floor(
//           (currentDate - tranDate) / (1000 * 60 * 60 * 24)
//         ),
//       };

//       customer.totalSpend += parseFloat(transaction.total);
//       customer.purchaseCount += 1;

//       if (tranDate > customer.lastPurchaseDate) {
//         customer.lastPurchaseDate = tranDate;
//         customer.daysSinceLastPurchase = Math.floor(
//           (currentDate - tranDate) / (1000 * 60 * 60 * 24)
//         );
//       }

//       if (!customer.transactions) {
//         customer.transactions = [];
//       }

//       customer.transactions.push(transactionDetail);

//       customerMap.set(customerKey, customer);
//     }
//   });

//   const categories = {
//     loyalists: [],
//     potentialLoyalists: [],
//     promising: [],
//     hesitant: [],
//     needAttention: [],
//     detractors: [],
//     customers: [],
//   };

//   customerMap.forEach((customer, name) => {
//     const recentPurchase = customer.lastPurchaseDate >= thirtyDaysAgo;
//     const pastMonthPurchase =
//       customer.lastPurchaseDate >= ninetyDaysAgo &&
//       customer.lastPurchaseDate < thirtyDaysAgo;

//     const lastPurchaseOver90Days = customer.lastPurchaseDate < ninetyDaysAgo;

//     //^ GET THE CUSTOMERS 2024 TRANSACTIONS ONLY
//     let newCustomers2024 = [];
//     const lastPurchaseYear = new Date(customer.lastPurchaseDate).getFullYear();

//     // Check if the last purchase year is 2024
//     if (lastPurchaseYear === 2024) {
//       newCustomers2024.push({ name, customer });
//     }

//     if (
//       customer.totalSpend >= 5000 &&
//       customer.purchaseCount >= 6 &&
//       recentPurchase
//     ) {
//       categories.loyalists.push(customer);
//     } else if (
//       customer.totalSpend >= 2000 &&
//       customer.totalSpend < 5000 &&
//       customer.purchaseCount === 5 &&
//       recentPurchase
//     ) {
//       categories.potentialLoyalists.push(customer);
//     } else if (
//       customer.totalSpend >= 2000 &&
//       customer.totalSpend < 5000 &&
//       customer.purchaseCount === 4 &&
//       recentPurchase
//     ) {
//       categories.promising.push(customer);
//     } else if (
//       customer.totalSpend < 2000 &&
//       customer.purchaseCount === 3 &&
//       recentPurchase
//     ) {
//       categories.hesitant.push(customer);
//     } else if (
//       customer.totalSpend >= 2000 &&
//       customer.totalSpend < 5000 &&
//       customer.purchaseCount === 2 &&
//       recentPurchase
//     ) {
//       categories.needAttention.push(customer);
//     } else if (
//       customer.totalSpend < 2000 &&
//       customer.purchaseCount === 1 &&
//       recentPurchase
//     ) {
//       categories.detractors.push(customer);
//     }
//   });

//   // console.log("length", customerMap.size)

//   return categories;
// };

// export default getCustomerCategories;

// const getCustomerCategories = (transactions, selectedStore) => {
//   console.log("bilang", transactions);
//   console.log("stores", selectedStore);

//   const oneYearAgo = new Date();
//   oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

//   const sixMonthsAgo = new Date();
//   sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

//   const threeMonthsAgo = new Date();
//   threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

//   const currentDate = new Date();
//   const customerMap = new Map();

//   transactions.forEach((transaction) => {
//     const storeCode = transaction.store_code.replace("ANGELSPIZZA_", "");
//     if (selectedStore !== "All" && storeCode !== selectedStore) {
//       return;
//     }

//     const tranDate = new Date(transaction.tran_date);

//     const transactionDetail = {
//       date: tranDate.toLocaleDateString(),
//       totalSpend: parseFloat(transaction.total),
//     };

//     if (tranDate >= oneYearAgo) {
//       let customerKey;

//       if (transaction.cust_name === "-") {
//         // Use a unique key for anonymous customers
//         customerKey = `${transaction.cust_phone}_${tranDate.getTime()}`;
//       } else {
//         // Group customers by phone and name
//         customerKey = `${transaction.cust_phone}_${transaction.cust_name}`;
//       }

//       const existingCustomer = customerMap.get(customerKey);
//       const customer = existingCustomer || {
//         custPhone: transaction.cust_phone,
//         custName: transaction.cust_name,
//         totalSpend: 0,
//         purchaseCount: 0,
//         lastPurchaseDate: tranDate,
//         custEmail: transaction.cust_email,
//         custMobile: transaction.cust_mobile,
//         daysSinceLastPurchase: Math.floor(
//           (currentDate - tranDate) / (1000 * 60 * 60 * 24)
//         ),
//       };

//       customer.totalSpend += parseFloat(transaction.total);
//       customer.purchaseCount += 1;

//       if (tranDate > customer.lastPurchaseDate) {
//         customer.lastPurchaseDate = tranDate;
//         customer.daysSinceLastPurchase = Math.floor(
//           (currentDate - tranDate) / (1000 * 60 * 60 * 24)
//         );
//       }

//       if (!customer.transactions) {
//         customer.transactions = [];
//       }

//       customer.transactions.push(transactionDetail);

//       customerMap.set(customerKey, customer);
//     }
//   });

//   const categories = {
//     loyalists: [],
//     potentialLoyalists: [],
//     promising: [],
//     hesitant: [],
//     needAttention: [],
//     detractors: [],
//     customers: [],
//   };

//   customerMap.forEach((customer, name) => {
//     const recentPurchase = customer.lastPurchaseDate >= threeMonthsAgo;
//     const pastSixMonthsPurchase =
//       customer.lastPurchaseDate >= sixMonthsAgo &&
//       customer.lastPurchaseDate < threeMonthsAgo;

//     const pastYearPurchase =
//       customer.lastPurchaseDate >= oneYearAgo &&
//       customer.lastPurchaseDate < sixMonthsAgo;

//     // Get the total spend and purchase count within the last year
//     const totalSpend = customer.totalSpend;
//     const purchaseCount = customer.purchaseCount;
//     const daysSinceLastPurchase = customer.daysSinceLastPurchase;

//     if (
//       totalSpend >= 5000 &&
//       purchaseCount >= 10 &&
//       daysSinceLastPurchase <= 90
//     ) {
//       categories.loyalists.push(customer);
//     } else if (
//       totalSpend >= 3000 &&
//       purchaseCount >= 8 &&
//       daysSinceLastPurchase <= 180
//     ) {
//       categories.potentialLoyalists.push(customer);
//     } else if (
//       totalSpend >= 2000 &&
//       purchaseCount >= 6 &&
//       daysSinceLastPurchase <= 180
//     ) {
//       categories.promising.push(customer);
//     } else if (
//       totalSpend >= 1000 &&
//       purchaseCount >= 4 &&
//       daysSinceLastPurchase <= 180
//     ) {
//       categories.hesitant.push(customer);
//     } else if (
//       totalSpend >= 1000 &&
//       purchaseCount >= 2 &&
//       daysSinceLastPurchase <= 365
//     ) {
//       categories.needAttention.push(customer);
//     } else if (
//       totalSpend >= 1000 &&
//       purchaseCount === 1 &&
//       daysSinceLastPurchase <= 365
//     ) {
//       categories.detractors.push(customer);
//     }
//   });

//   return categories;
// };

// export default getCustomerCategories;

// const getCustomerCategories = (transactions, selectedStore) => {
//   console.log("bilang", transactions);
//   console.log("stores", selectedStore);

//   const oneYearAgo = new Date();
//   oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

//   const sixMonthsAgo = new Date();
//   sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

//   const threeMonthsAgo = new Date();
//   threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

//   const currentDate = new Date();
//   const customerMap = new Map();

//   transactions.forEach((transaction) => {
//     const storeCode = transaction.store_code.replace("ANGELSPIZZA_", "");
//     if (selectedStore !== "All" && storeCode !== selectedStore) {
//       return;
//     }

//     const tranDate = new Date(transaction.tran_date);

//     const transactionDetail = {
//       date: tranDate.toLocaleDateString(),
//       totalSpend: parseFloat(transaction.total),
//     };

//     if (tranDate >= oneYearAgo) {
//       let customerKey;

//       if (transaction.cust_name === "-") {
//         // Use a unique key for anonymous customers
//         customerKey = `${transaction.cust_phone}_${tranDate.getTime()}`;
//       } else {
//         // Group customers by phone and name
//         customerKey = `${transaction.cust_phone}_${transaction.cust_name}`;
//       }

//       const existingCustomer = customerMap.get(customerKey);
//       const customer = existingCustomer || {
//         custPhone: transaction.cust_phone,
//         custName: transaction.cust_name,
//         totalSpend: 0,
//         purchaseCount: 0,
//         lastPurchaseDate: tranDate,
//         custEmail: transaction.cust_email,
//         custMobile: transaction.cust_mobile,
//         daysSinceLastPurchase: Math.floor(
//           (currentDate - tranDate) / (1000 * 60 * 60 * 24)
//         ),
//       };

//       customer.totalSpend += parseFloat(transaction.total);
//       customer.purchaseCount += 1;

//       if (tranDate > customer.lastPurchaseDate) {
//         customer.lastPurchaseDate = tranDate;
//         customer.daysSinceLastPurchase = Math.floor(
//           (currentDate - tranDate) / (1000 * 60 * 60 * 24)
//         );
//       }

//       if (!customer.transactions) {
//         customer.transactions = [];
//       }

//       customer.transactions.push(transactionDetail);

//       customerMap.set(customerKey, customer);
//     }
//   });

//   const categories = {
//     loyalists: [],
//     potentialLoyalists: [],
//     promising: [],
//     hesitant: [],
//     needAttention: [],
//     detractors: [],
//     customers: [],
//   };

//   customerMap.forEach((customer, name) => {
//     const threeMonthsTransactions = customer.transactions.filter(
//       (t) => new Date(t.date) >= threeMonthsAgo
//     );
//     const sixMonthsTransactions = customer.transactions.filter(
//       (t) => new Date(t.date) >= sixMonthsAgo
//     );
//     const oneYearTransactions = customer.transactions.filter(
//       (t) => new Date(t.date) >= oneYearAgo
//     );

//     const totalSpendThreeMonths = threeMonthsTransactions.reduce(
//       (sum, t) => sum + t.totalSpend,
//       0
//     );
//     const totalSpendSixMonths = sixMonthsTransactions.reduce(
//       (sum, t) => sum + t.totalSpend,
//       0
//     );
//     const totalSpendOneYear = oneYearTransactions.reduce(
//       (sum, t) => sum + t.totalSpend,
//       0
//     );

//     const countThreeMonths = threeMonthsTransactions.length;
//     const countSixMonths = sixMonthsTransactions.length;
//     const countOneYear = oneYearTransactions.length;

//     const daysSinceLastPurchase = customer.daysSinceLastPurchase;

//     if (
//       totalSpendThreeMonths >= 5000 &&
//       countThreeMonths >= 10 &&
//       daysSinceLastPurchase <= 90
//     ) {
//       categories.loyalists.push(customer);
//     } else if (
//       totalSpendSixMonths >= 3000 &&
//       countSixMonths >= 8 &&
//       daysSinceLastPurchase <= 180
//     ) {
//       categories.potentialLoyalists.push(customer);
//     } else if (
//       totalSpendSixMonths >= 2000 &&
//       countSixMonths >= 6 &&
//       daysSinceLastPurchase <= 180
//     ) {
//       categories.promising.push(customer);
//     } else if (
//       totalSpendSixMonths >= 1000 &&
//       countSixMonths >= 4 &&
//       daysSinceLastPurchase <= 180
//     ) {
//       categories.hesitant.push(customer);
//     } else if (
//       totalSpendOneYear >= 1000 &&
//       countOneYear >= 2 &&
//       daysSinceLastPurchase <= 365
//     ) {
//       categories.needAttention.push(customer);
//     } else if (
//       totalSpendOneYear >= 1000 &&
//       countOneYear === 1 &&
//       daysSinceLastPurchase <= 365
//     ) {
//       categories.detractors.push(customer);
//     }
//   });

//   return categories;
// };

// export default getCustomerCategories;

// latest with fix

// const getCustomerCategories = (transactions, selectedStore) => {
//   console.log("bilang", transactions);
//   console.log("stores", selectedStore);

//   const oneYearAgo = new Date();
//   oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

//   const sixMonthsAgo = new Date();
//   sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

//   const threeMonthsAgo = new Date();
//   threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

//   const currentDate = new Date();
//   const customerMap = new Map();

//   transactions.forEach((transaction) => {
//     const storeCode = transaction.store_code.replace("ANGELSPIZZA_", "");
//     if (selectedStore !== "All" && storeCode !== selectedStore) {
//       return;
//     }

//     const tranDate = new Date(transaction.tran_date);

//     const transactionDetail = {
//       date: tranDate.toLocaleDateString(),
//       totalSpend: parseFloat(transaction.total),
//     };

//     if (tranDate >= oneYearAgo) {
//       let customerKey;

//       if (transaction.cust_name === "-") {
//         // Use a unique key for anonymous customers
//         customerKey = `${transaction.cust_phone}_${tranDate.getTime()}`;
//       } else {
//         // Group customers by phone and name
//         customerKey = `${transaction.cust_phone}_${transaction.cust_name}`;
//       }

//       const existingCustomer = customerMap.get(customerKey);
//       const customer = existingCustomer || {
//         custPhone: transaction.cust_phone,
//         custName: transaction.cust_name,
//         totalSpend: 0,
//         purchaseCount: 0,
//         lastPurchaseDate: tranDate,
//         custEmail: transaction.cust_email,
//         custMobile: transaction.cust_mobile,
//         daysSinceLastPurchase: Math.floor(
//           (currentDate - tranDate) / (1000 * 60 * 60 * 24)
//         ),
//       };

//       customer.totalSpend += parseFloat(transaction.total);
//       customer.purchaseCount += 1;

//       if (tranDate > customer.lastPurchaseDate) {
//         customer.lastPurchaseDate = tranDate;
//         customer.daysSinceLastPurchase = Math.floor(
//           (currentDate - tranDate) / (1000 * 60 * 60 * 24)
//         );
//       }

//       if (!customer.transactions) {
//         customer.transactions = [];
//       }

//       customer.transactions.push(transactionDetail);

//       customerMap.set(customerKey, customer);
//     }
//   });

//   const categories = {
//     loyalists: [],
//     potentialLoyalists: [],
//     promising: [],
//     hesitant: [],
//     needAttention: [],
//     detractors: [],
//     customers: [],
//   };

//   customerMap.forEach((customer, name) => {
//     const threeMonthsTransactions = customer.transactions.filter(
//       (t) => new Date(t.date) >= threeMonthsAgo
//     );
//     const sixMonthsTransactions = customer.transactions.filter(
//       (t) => new Date(t.date) >= sixMonthsAgo
//     );
//     const oneYearTransactions = customer.transactions.filter(
//       (t) => new Date(t.date) >= oneYearAgo
//     );

//     const totalSpendThreeMonths = threeMonthsTransactions.reduce(
//       (sum, t) => sum + t.totalSpend,
//       0
//     );
//     const totalSpendSixMonths = sixMonthsTransactions.reduce(
//       (sum, t) => sum + t.totalSpend,
//       0
//     );
//     const totalSpendOneYear = oneYearTransactions.reduce(
//       (sum, t) => sum + t.totalSpend,
//       0
//     );

//     const countThreeMonths = threeMonthsTransactions.length;
//     const countSixMonths = sixMonthsTransactions.length;
//     const countOneYear = oneYearTransactions.length;

//     const daysSinceLastPurchase = customer.daysSinceLastPurchase;

//     if (
//       totalSpendThreeMonths >= 5000 &&
//       countThreeMonths >= 10 &&
//       daysSinceLastPurchase <= 90
//     ) {
//       categories.loyalists.push(customer);
//       console.log("Loyalist:", customer);
//     } else if (
//       totalSpendSixMonths >= 3000 &&
//       countSixMonths >= 8 &&
//       daysSinceLastPurchase <= 180
//     ) {
//       categories.potentialLoyalists.push(customer);
//       console.log("Potential Loyalist:", customer);
//     } else if (
//       totalSpendSixMonths >= 2000 &&
//       countSixMonths >= 6 &&
//       daysSinceLastPurchase <= 180
//     ) {
//       categories.promising.push(customer);
//       console.log("Promising:", customer);
//     } else if (
//       totalSpendSixMonths >= 1000 &&
//       countSixMonths >= 4 &&
//       daysSinceLastPurchase <= 180
//     ) {
//       categories.hesitant.push(customer);
//       console.log("Hesitant:", customer);
//     } else if (
//       totalSpendOneYear >= 1000 &&
//       countOneYear >= 2 &&
//       daysSinceLastPurchase <= 365
//     ) {
//       categories.needAttention.push(customer);
//       console.log("Need Attention:", customer);
//     } else if (
//       totalSpendOneYear >= 1000 &&
//       countOneYear === 1 &&
//       daysSinceLastPurchase <= 365
//     ) {
//       categories.detractors.push(customer);
//       console.log("Detractor:", customer);
//     }
//   });

//   return categories;
// };

// export default getCustomerCategories;

const getCustomerCategories = (transactions, selectedStore) => {
  // console.log("bilang", transactions);
  // console.log("stores", selectedStore);

  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

  const sixMonthsAgo = new Date();
  sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

  const threeMonthsAgo = new Date();
  threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

  const currentDate = new Date();
  const customerMap = new Map();

  transactions.forEach((transaction) => {
    const storeCode = transaction.store_code.replace("ANGELSPIZZA_", "");
    if (selectedStore !== "All" && storeCode !== selectedStore) {
      return;
    }

    const tranDate = new Date(transaction.tran_date);

    const transactionDetail = {
      date: tranDate.toLocaleDateString(),
      totalSpend: parseFloat(transaction.total),
    };

    if (tranDate >= oneYearAgo) {
      let customerKey;

      if (transaction.cust_name === "-") {
        // Use a unique key for anonymous customers
        customerKey = `${transaction.cust_phone}_${tranDate.getTime()}`;
      } else {
        // Group customers by phone and name
        customerKey = `${transaction.cust_phone}_${transaction.cust_name}`;
      }

      const existingCustomer = customerMap.get(customerKey);
      const customer = existingCustomer || {
        custPhone: transaction.cust_phone,
        custName: transaction.cust_name,
        totalSpend: 0,
        purchaseCount: 0,
        lastPurchaseDate: tranDate,
        custEmail: transaction.cust_email,
        custMobile: transaction.cust_mobile,
        daysSinceLastPurchase: Math.floor(
          (currentDate - tranDate) / (1000 * 60 * 60 * 24)
        ),
      };

      customer.totalSpend += parseFloat(transaction.total);
      customer.purchaseCount += 1;

      if (tranDate > customer.lastPurchaseDate) {
        customer.lastPurchaseDate = tranDate;
        customer.daysSinceLastPurchase = Math.floor(
          (currentDate - tranDate) / (1000 * 60 * 60 * 24)
        );
      }

      if (!customer.transactions) {
        customer.transactions = [];
      }

      customer.transactions.push(transactionDetail);

      customerMap.set(customerKey, customer);
    }
  });

  const categories = {
    loyalists: [],
    potentialLoyalists: [],
    promising: [],
    hesitant: [],
    needAttention: [],
    detractors: [],
    customers: [],
  };

  customerMap.forEach((customer, name) => {
    const threeMonthsTransactions = customer.transactions.filter(
      (t) => new Date(t.date) >= threeMonthsAgo
    );
    const sixMonthsTransactions = customer.transactions.filter(
      (t) => new Date(t.date) >= sixMonthsAgo
    );
    const oneYearTransactions = customer.transactions.filter(
      (t) => new Date(t.date) >= oneYearAgo
    );

    const totalSpendOneYear = oneYearTransactions.reduce(
      (sum, t) => sum + t.totalSpend,
      0
    );

    const countThreeMonths = threeMonthsTransactions.length;
    const countSixMonths = sixMonthsTransactions.length;
    const countOneYear = oneYearTransactions.length;

    const daysSinceLastPurchase = customer.daysSinceLastPurchase;

    if (
      totalSpendOneYear >= 5000 &&
      countThreeMonths >= 10 &&
      daysSinceLastPurchase <= 90
    ) {
      categories.loyalists.push(customer);
      // console.log("Loyalist:", customer);
    } else if (
      totalSpendOneYear >= 3000 &&
      countSixMonths >= 8 &&
      daysSinceLastPurchase <= 180
    ) {
      categories.potentialLoyalists.push(customer);
      // console.log("Potential Loyalist:", customer);
    } else if (
      totalSpendOneYear >= 2000 &&
      countSixMonths >= 6 &&
      daysSinceLastPurchase <= 180
    ) {
      categories.promising.push(customer);
      // console.log("Promising:", customer);
    } else if (
      totalSpendOneYear >= 1000 &&
      countSixMonths >= 4 &&
      daysSinceLastPurchase <= 180
    ) {
      categories.hesitant.push(customer);
      // console.log("Hesitant:", customer);
    } else if (
      totalSpendOneYear >= 1000 &&
      countOneYear >= 2 &&
      daysSinceLastPurchase <= 365
    ) {
      categories.needAttention.push(customer);
      // console.log("Need Attention:", customer);
    } else if (
      totalSpendOneYear >= 1000 &&
      countOneYear === 1 &&
      daysSinceLastPurchase <= 365
    ) {
      categories.detractors.push(customer);
      // console.log("Detractor:", customer);
    }
  });

  return categories;
};

export default getCustomerCategories;

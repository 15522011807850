export const grossSaleCalculator = (transactions) => {
  const results = [];

  const filteredTransactions = transactions?.filter(
    (status) => status.status === "C"
  );

  const uniqueStoreCodes = [
    ...new Set(
      filteredTransactions?.map((transaction) => transaction.store_code)
    ),
  ];

  uniqueStoreCodes.forEach((storeCode) => {
    const storeTransactions = filteredTransactions?.filter(
      (transaction) => transaction.store_code === storeCode
    );

    const [brand, location] = storeCode.split("_");

    const total = storeTransactions.reduce(
      (sum, transaction) => sum + parseFloat(transaction.total),
      0
    );
    // const discountAmount = storeTransactions.reduce(
    //   (sum, transaction) => sum + parseFloat(transaction.discount_amount),
    //   0
    // );
    const discountAmount = storeTransactions.reduce(
      (sum, transaction) =>
        sum +
        (isNaN(parseFloat(transaction.discount_amount))
          ? 0
          : parseFloat(transaction.discount_amount)),
      0
    );

    const totalTax = storeTransactions.reduce(
      (sum, transaction) => sum + parseFloat(transaction.total_tax),
      0
    );
    const grossIncome = total + discountAmount + totalTax;

    results.push({
      code: storeCode,
      brand: brand,
      location: location,
      total: total.toFixed(2),
      discountAmount: discountAmount.toFixed(2),
      years: 2024,
      totalTax: totalTax.toFixed(2),
      gross_sale: grossIncome.toFixed(2),
    });
  });

  return results;
};

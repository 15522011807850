import React, { useState, useEffect, useMemo, useRef } from "react";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import { calculateDoughnutData } from "./calculateDoughnutData";
import { InputSelect } from "nq-component";
import Export from "./components/Export";
import "./index.css";
import printChart from "../../../PrintChart";

const tooltipOptions = {
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
      callbacks: {
        label: function (context) {
          let label = context.label || "";
          let value = context.parsed;
          return label + ": " + value;
        },
      },
    },
  },
};

function DoughnutChartCard({ data, options, title }) {
  return (
    <div className="card">
      <div className="card-body">
        <h6 className="card-title">{title}</h6>
        <Doughnut data={data} options={options} />
      </div>
    </div>
  );
}

function ShareSalesContri({ objects, storess }) {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [optionss, setOptionss] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const shareRef = useRef();

  console.log("ob", objects);

  // console.log(objects);

  // Pre-process ung objects para sa date parsing
  // Modify the useMemo that processes the objects
  const parsedObjects = useMemo(() => {
    return objects.map((obj) => {
      const parts = obj.store_code.split("ANGELSPIZZA_");
      const codePart = parts.length > 1 ? parts[1] : ""; // Get the part after 'ANGELSPIZZA_'
      return {
        ...obj,

        storeCodePart: codePart, // Add this property
      };
    });
  }, [objects]);

  // Update filtered data when selections change
  // useEffect(() => {
  //   const filtered = parsedObjects.filter((obj) => {
  //     const matchesOption = selectedOption
  //       ? obj.storeCodePart === selectedOption // Compare using the new storeCodePart
  //       : true;

  //     // Date filtering integrated directly
  //     const createdAt = new Date(obj.createdAt);
  //     const dateFilterPassed =
  //       !startDate ||
  //       !endDate ||
  //       (createdAt >= new Date(startDate) && createdAt <= new Date(endDate));

  //     return matchesOption && dateFilterPassed;
  //   });

  //   setFilteredData(filtered);
  // }, [selectedOption, startDate, endDate, parsedObjects]);
  useEffect(() => {
    const filtered = parsedObjects.filter((obj) => {
      const matchesOption = selectedOption
        ? obj.storeCodePart === selectedOption
        : true;
      const createdAt = new Date(obj.createdAt);
      const start = startDate ? new Date(startDate) : new Date(0);
      let end = endDate ? new Date(endDate) : new Date();
      end.setHours(23, 59, 59, 999); // Include the whole day

      const dateFilterPassed = createdAt >= start && createdAt <= end;
      return matchesOption && dateFilterPassed;
    });

    setFilteredData(filtered);
  }, [selectedOption, startDate, endDate, parsedObjects]);

  //Function to add options in store
  useEffect(() => {
    processData();
  }, [objects]);

  const processData = () => {
    const data = objects;

    const storeCodes = data.reduce((acc, item) => {
      const code = item.store_code.split("ANGELSPIZZA_")[1]; // Remove prefix
      if (code && !acc.includes(code)) {
        acc.push(code);
      }
      return acc;
    }, []);

    setOptionss(storeCodes.map((code) => ({ value: code, label: code })));
  };
  const onChangeType = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  // Use this function before passing the data to calculateDoughnutData
  // const filteredData = filterDataByDate(objects);

  const titles = [
    "Brand",
    "Group Size",
    "Transaction Type",
    // "Service Channel",
    "Payment Type",
    "Region",
    "Store Category",
    "Location",
  ];

  const doughnutData = calculateDoughnutData(filteredData, tooltipOptions);

  function convertToCSV(objArray) {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str =
      `${Object.keys(array[0])
        .map((value) => `"${value}"`)
        .join(",")}` + "\r\n";

    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line !== "") line += ",";

        line += `"${array[i][index]}"`;
      }

      str += line + "\r\n";
    }

    return str;
  }

  const exportToCSV = () => {
    const csvData = [];
    Object.entries(filteredData).forEach(([monthYear, data]) => {
      console.log(data);
      csvData.push({
        brand: "Angels Pizza",
        store: storess,
        monthYear,
        sales: data.total,
      });
    });

    const csvString = convertToCSV(csvData);
    const a = document.createElement("a");
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = "share-sales-contribution.csv";
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const onClickExport = () => {
    window.print();
  };

  const printId = "chart-container10";

  return (
    <div className="container">
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="fw-bold text-capitalize">
          <span style={{ color: "darkcolor" }}>Share of</span>
          <span style={{ color: "#83c14a" }}> Sales Contribution</span>
        </h2>

        <Export exportCsv={exportToCSV} exportPdf={() => printChart(printId)} />
      </div>

      <div className="row mb-2 ms-1"></div>
      {/* Desktop View */}
      <div
        ref={shareRef}
        id="chart-container10"
        className="row row-cols-1 row-cols-md-4 g-4 d-none d-md-flex"
      >
        {doughnutData.map((data, index) => (
          <div key={index} className="col">
            <DoughnutChartCard
              data={data}
              options={tooltipOptions}
              title={titles[index]}
            />
          </div>
        ))}
      </div>

      {/* Mobile View */}
      <div className="d-flex d-md-none flex-nowrap overflow-auto">
        {doughnutData.map((data, index) => (
          <div key={index} className="col-6 col-md-3">
            <DoughnutChartCard
              data={data}
              options={tooltipOptions}
              title={titles[index]}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default ShareSalesContri;

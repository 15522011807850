import React from "react";
import BaseListPage from "../../base/BaseListPage";
import withRouter from "../../withRouter";
import { countObjectUseCase, findObjectUseCase } from "../../usecases/object";
import NavBar from "../../components/navbar";
import schemaDashboard from "./schemaDashboard.json";
import Count from "../dashboard/components/Count";
import DateRange from "../../components/DateRange";
import ProductsPresenter from "./ProductsPresenter";
import TopItemRevenuePage from "./chart/top-item-revenue/TopItemRevenuePage";
import TopItemQuantityPage from "./chart/top-item-quantity/TopItemQuantityPage";
import BottomItemRevenuePage from "./chart/bottom-item-revenue/BottomItemRevenuePage";
import BottomItemQuantityPage from "./chart/buttom-item-quantity/ButtomItemQuantityPage";
import { Bar } from "react-chartjs-2";
import Export from "../../components/Export";
import printJS from "print-js";
import DateRangeReport from "../../components/DateRangeReport";
import { Progress } from "nq-component";

class ProductsPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.presenter = new ProductsPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase()
    );
    this.state = {
      objects: [],
      selected: [],
      progress: true,
      count: 0,
      where: {},
      range: "",
      itemDataRevenue: {},
      itemDataQuantity: {},
      storess: "",
      store: [],
      printThisData: [],
    };
  }

  componentDidMount() {
    this.presenter.componentDidMount();
    if (this.state.objects.length > 0) {
      this.calculateTotalSalesByItem();
      this.calculateTotalQuantityByItem();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.objects.length > 0 &&
      prevState.objects !== this.state.objects
    ) {
      this.calculateTotalSalesByItem();
      this.calculateTotalQuantityByItem();
    }
  }

  formatSales(sales) {
    return sales.toLocaleString("en-US", {
      style: "currency",
      currency: "PHP",
    });
  }

  getCollectionName() {
    return "transactions";
  }

  setStore(store) {
    this.setState({ store });
  }

  onChangeDate(where) {
    this.presenter.onChangeDate(where);
    this.setState({ range: where });
  }

  onChangeStore(where) {
    this.setState({ storess: where });
    this.presenter.onChangeStore(where);
  }

  getValueForDashboardObject(object) {
    switch (object.label) {
      case "Total Item Sold":
        return this.presenter.calculateTotalItemSoldSalesInfo();
      case "Total Transaction":
        return this.presenter.calculateTotalTransactionInfo();
      case "Total Guest":
        return this.presenter.calculateTotalGuestInfo();
      case "Discount Cost":
        return this.presenter.calculateDiscountCost();
      default:
        return { value: 0, percentage: 0 };
    }
  }

  handleCountClick(nav) {
    this.navigateTo(nav);
  }

  async calculateTotalQuantityByItemAsync(objects) {
    const a = objects
      .filter((obj) => obj.status === "C")
      .reduce((acc, transaction) => {
        transaction.items.forEach((item) => {
          const { code: itemCode, description, quantity } = item;
          const itemQuantity = parseInt(quantity, 10);
          const unitPrice = parseFloat(
            item.customProperties.find((prop) => prop.codeName === "UNIT_PRICE")
              ?.value || 0
          );

          if (!isNaN(itemQuantity) && !isNaN(unitPrice) && unitPrice > 0) {
            const itemSales = unitPrice * itemQuantity;
            if (!acc[itemCode]) {
              // If the item code is not yet in the accumulator, initialize it
              acc[itemCode] = {
                quantity: itemQuantity,
                sales: itemSales,
                description,
              };
            } else {
              // If the item code is already in the accumulator, update its quantity and sales
              acc[itemCode].quantity += itemQuantity;
              acc[itemCode].sales += itemSales; // Accumulate sales
            }
          }
        });
        return acc;
      }, {});
    return a;
  }

  async calculateTotalSalesByItemAsync(objects) {
    const totalSalesByItem = objects
      .filter((obj) => obj.status === "C")
      .reduce((acc, transaction) => {
        transaction.items.forEach((item) => {
          const { code: itemCode, description, quantity } = item;
          const itemQuantity = parseInt(quantity, 10);
          const unitPrice = parseFloat(
            item.customProperties.find((prop) => prop.codeName === "UNIT_PRICE")
              ?.value || 0
          );

          if (!isNaN(itemQuantity) && !isNaN(unitPrice) && unitPrice > 0) {
            if (!acc[itemCode]) {
              acc[itemCode] = {
                sales: unitPrice * itemQuantity,
                quantity: itemQuantity,
                description,
              };
            } else {
              acc[itemCode].sales += unitPrice * itemQuantity;
              acc[itemCode].quantity += itemQuantity;
            }
          }
        });
        return acc;
      }, {});

    return totalSalesByItem;
  }

  async calculateTotalSalesByItem() {
    const totalSalesByItem = await this.calculateTotalSalesByItemAsync(
      this.state.objects
    );
    this.setState({ itemDataRevenue: totalSalesByItem });
  }

  getChartDataBottomRevenue() {
    // Sorting items based on sales, which is now nested under each item code
    const sortedItems = Object.entries(this.state.itemDataRevenue)
      .sort((a, b) => a[1].sales - b[1].sales)
      .slice(0, 30);

    // Using the description as the label for each item
    const labels = sortedItems.map(([, item]) => item.description);
    const salesData = sortedItems.map(([, item]) => item.sales);

    return {
      labels: labels,
      datasets: [
        {
          indexAxis: "y",
          label: "Total Sales",
          data: salesData,
          backgroundColor: "#006096",
          borderColor: "#006096",
          borderWidth: 1,
        },
      ],
    };
  }

  getChartDataTopRevenue() {
    // Sorting items based on sales, which is now nested under each item code
    const sortedItems = Object.entries(this.state.itemDataRevenue)
      .sort((a, b) => b[1].sales - a[1].sales)
      .slice(0, 30);

    // Using the description as the label for each item
    const labels = sortedItems.map(([, item]) => item.description);
    const salesData = sortedItems.map(([, item]) => item.sales);

    return {
      labels: labels,
      datasets: [
        {
          indexAxis: "y",
          label: "Total Sales",
          data: salesData,
          backgroundColor: "#006096",
          borderColor: "#006096",
          borderWidth: 1,
        },
      ],
    };
  }

  async calculateTotalQuantityByItem() {
    const totalQuantityByItem = await this.calculateTotalQuantityByItemAsync(
      this.state.objects
    );
    this.setState({ itemDataQuantity: totalQuantityByItem });
  }

  getChartDataTopQuantity() {
    // Sorting items based on quantity, which is now nested under each item code
    const sortedItems = Object.entries(this.state.itemDataQuantity)
      .sort((a, b) => b[1].quantity - a[1].quantity)
      .slice(0, 30);

    // Using the description as the label for each item
    const labels = sortedItems.map(([, item]) => item.description);
    const quantityData = sortedItems.map(([, item]) => item.quantity);

    return {
      labels: labels,
      datasets: [
        {
          indexAxis: "y",
          label: "Total Quantity",
          data: quantityData,
          backgroundColor: "#006096",
          borderColor: "#006096",
          borderWidth: 1,
        },
      ],
    };
  }

  getChartDataBottomQuantity() {
    // Sorting items based on quantity, which is now nested under each item code
    const sortedItems = Object.entries(this.state.itemDataQuantity)
      .sort((a, b) => a[1].quantity - b[1].quantity)
      .slice(0, 30);

    // Using the description as the label for each item
    const labels = sortedItems.map(([, item]) => item.description);
    const quantityData = sortedItems.map(([, item]) => item.quantity);

    return {
      labels: labels,
      datasets: [
        {
          indexAxis: "y",
          label: "Total Quantity",
          data: quantityData,
          backgroundColor: "#006096",
          borderColor: "#006096",
          borderWidth: 1,
        },
      ],
    };
  }

  printChart(chartRefId) {
    setTimeout(() => {
      printJS({
        printable: chartRefId,
        type: "html",
        header: `<div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem;">
                   <img src="/figaro.png" alt="Figaro" style="height: 50px;"/>
                   <h4 style="margin: 0 auto;">FIGARO COFFEE GROUP</h4>
                   <img src="/angels.png" alt="Angel's Pizza" style="height: 50px;"/>
                 </div>`,
        css: "./index.css",
        scanStyles: false,
        style: ".print-only-content { display: block; }",
      });
    }, 500);
  }

  exportToCSV = (datasetType, isTop = true, numberOfItems = 30) => {
    const { storess, range, itemDataRevenue, itemDataQuantity } = this.state;

    let dataset = [];
    let fileNameSuffix = "";
    let title = ""; // Initialize title variable

    switch (datasetType) {
      case "revenue":
        dataset = Object.entries(itemDataRevenue)
          .sort((a, b) =>
            isTop ? b[1].sales - a[1].sales : a[1].sales - b[1].sales
          )
          .slice(0, numberOfItems)
          .map((data) => ({
            Store: storess,
            Date: `${range.createdAt["$gte"].split("T")[0]} - ${
              range.createdAt["$lte"].split("T")[0]
            }`,
            Item_Name: data[1].description,
            Revenue: data[1].sales,
            Quantity: data[1].quantity,
          }));
        fileNameSuffix = isTop ? "top-item-revenue" : "bottom-item-revenue";
        title = isTop ? "Top Item Revenue" : "Bottom Item Revenue";
        break;
      case "quantity":
        dataset = Object.entries(itemDataQuantity)
          .sort((a, b) =>
            isTop
              ? b[1].quantity - a[1].quantity
              : a[1].quantity - b[1].quantity
          )
          .slice(0, numberOfItems)
          .map((data) => ({
            Store: storess,
            Date: `${range.createdAt["$gte"].split("T")[0]} - ${
              range.createdAt["$lte"].split("T")[0]
            }`,
            Item_Name: data[1].description,
            Quantity: data[1].quantity,
            Revenue: data[1].sales,
          }));
        fileNameSuffix = isTop ? "top-item-quantity" : "bottom-item-quantity";
        title = isTop ? "Top Item Quantity" : "Bottom Item Quantity";
        break;
      default:
        console.error("Invalid dataset type");
        return;
    }

    const csvString = this.convertToCSV(dataset, title); // Pass title to convertToCSV
    const a = document.createElement("a");
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const dateRange = `${range.createdAt["$gte"].split("T")[0]}-${
      range.createdAt["$lte"].split("T")[0]
    }`;

    a.href = url;
    a.download = `${storess}-${dateRange}-${fileNameSuffix}.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  convertToCSV = (arr, title = "") => {
    // Include the title in the CSV string if provided
    let csvContent = title ? `${title}\n` : "";
    // Add the header row
    csvContent += Object.keys(arr[0]).join(",") + "\n";
    // Add the data rows
    arr.forEach((item) => {
      csvContent += Object.values(item).join(",") + "\n";
    });
    return csvContent;
  };

  render() {
    const objects = this.state.objects?.filter((obj) => obj.status === "C");
    const chartDataTopRevenue = this.getChartDataTopRevenue();
    const chartDataBottomRevenue = this.getChartDataBottomRevenue();
    const chartDataTopQuantity = this.getChartDataTopQuantity();
    const chartDataBottomQuantity = this.getChartDataBottomQuantity();
    // console.warn("Get Objects :", objects);

    // if (objects.length === 0)
    //   return (
    //     <div className="text-center mt-5">
    //       <Progress />
    //       <h6>Processing... Please wait.</h6>
    //     </div>
    //   );

    return (
      <>
        <NavBar />
        <div className="overflow-auto">
          <div className="p-3 p-lg-4">
            <div className="d-flex justify-content-between align-items-center">
              <h1
                className="fw-bold mt-3 text-capitalize"
                style={{ color: "#83c14a" }}
              >
                Products Analytics
              </h1>
            </div>
            <div className="row mb-2 ">
              <div className="col-sm-4" style={{ maxWidth: "max-content" }}>
                <DateRangeReport
                  onChange={this.onChangeDate.bind(this)}
                  field="tran_date"
                />
              </div>

              <div className="col-sm-3">
                <select
                  className="form-select"
                  // value={this.state.selectedStoreCode}
                  onChange={(e) => this.onChangeStore(e.target.value)}
                  disabled={this.state.range ? false : true}
                >
                  <option value="">Select Store</option>
                  <option value="AllStore">All Store</option>
                  {this.state.store.map((code, index) => (
                    <option key={index} value={code.name}>
                      {code.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>{" "}
            {this.state.objects?.length > 0 ? (
              <>
                <div className="row mt-1 g-3">
                  {schemaDashboard.map((object, index) => {
                    const { value, percentage } =
                      this.getValueForDashboardObject(object, objects);

                    const navigationTarget = object.navigationTarget;
                    return (
                      <div key={index} className="col-6 col-md-3">
                        <Count
                          collection={object.collection}
                          icon={object.icon}
                          where={object.where}
                          val={value}
                          label={object.label}
                          labelAction={object.labelAction}
                          barangayValue={this.state.barangayValue}
                          object={object}
                          percentage={percentage}
                          bg={object.bg}
                          onClick={() =>
                            this.handleCountClick(navigationTarget)
                          }
                        />
                      </div>
                    );
                  })}
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <h1 className="fw-bold mt-3 text-capitalize text-black">
                    Top <span style={{ color: "#83c14a" }}>Menu Items</span> by{" "}
                    <span style={{ color: "#D1DE48" }}>Revenue</span>
                  </h1>
                  <Export
                    exportPdf={() =>
                      this.printChart("chart-container-top-revenue")
                    }
                    exportCsv={() => this.exportToCSV("revenue", true, 100)}
                  />
                </div>
                {this.state.objects?.length > 0 ? (
                  <div
                    id="chart-container-top-revenue"
                    className="chart-container"
                  >
                    <div className="card">
                      <Bar data={chartDataTopRevenue} />
                    </div>
                    <div className="print-only-content">
                      <h2>Report Details</h2>
                      <div className="d-flex justify-content-between align-items-center print-only-content">
                        <h3>Brand: Angels Pizza</h3>
                        <h4 className="mx-auto"></h4>
                        <h3>Store: {this.state.storess}</h3>
                        <h3>
                          Date Range:{" "}
                          <span>
                            {this.state.range &&
                              this.state.range?.tran_date["$gte"].split("T")[0]}
                          </span>{" "}
                          <span>
                            {this.state.range &&
                              this.state.range?.tran_date["$lte"].split("T")[0]}
                          </span>
                        </h3>
                        <h3>Top Menu Items By Revenue</h3>
                      </div>
                      <div className="print-only" style={{ fontSize: "20px" }}>
                        <table className="table text-center">
                          <thead>
                            <tr className="d-flex">
                              <th>Menu Item</th>
                              <th className="ms-5">Revenue</th>
                              <th className="ms-5">Quantity</th>
                            </tr>{" "}
                          </thead>
                          <tbody>
                            {Object.entries(this.state.itemDataRevenue)
                              .sort((a, b) => b[1].sales - a[1].sales)
                              .slice(0, 100)
                              .map(([itemCode, itemDetails]) => (
                                <tr key={itemCode}>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    {itemDetails.description}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    {this.formatSales(itemDetails.sales)}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    {itemDetails.quantity}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="text-center align-items-center mt-4">
                    <img
                      src="./choosefirst.png"
                      style={{ height: "200px", width: "200px" }}
                    />
                    <h4>To proceed, please select a date first</h4>
                  </div>
                )}

                <div className="d-flex justify-content-between align-items-center">
                  <h1 className="fw-bold mt-3 text-capitalize text-black">
                    Bottom <span style={{ color: "#83c14a" }}>Menu Items</span>{" "}
                    by <span style={{ color: "#D1DE48" }}>Revenue</span>
                  </h1>
                  <Export
                    exportPdf={() =>
                      this.printChart("chart-container-bottom-revenue")
                    }
                    exportCsv={() => this.exportToCSV("revenue", false, 100)}
                  />
                </div>
                {this.state.objects?.length > 0 ? (
                  <div
                    id="chart-container-bottom-revenue"
                    className="chart-container"
                  >
                    <div className="card">
                      <Bar data={chartDataBottomRevenue} />
                    </div>
                    <div className="print-only-content">
                      <h2>Report Details</h2>
                      <div className="d-flex justify-content-between align-items-center print-only-content">
                        <h3>Brand: Angels Pizza</h3>
                        <h4 className="mx-auto"></h4>
                        <h3>Store: {this.state.storess}</h3>
                        <h3>
                          Date Range:{" "}
                          <span>
                            {this.state.range &&
                              this.state.range?.tran_date["$gte"].split("T")[0]}
                          </span>{" "}
                          <span>
                            {this.state.range &&
                              this.state.range?.tran_date["$lte"].split("T")[0]}
                          </span>
                        </h3>
                        <h3>Bottom Menu Items By Revenue</h3>
                      </div>
                      <div className="print-only" style={{ fontSize: "20px" }}>
                        <table className="table text-center">
                          <thead>
                            <tr className="d-flex">
                              <th>Menu Item</th>
                              <th className="ms-5">Revenue</th>
                              <th className="ms-5">Quantity</th>
                            </tr>{" "}
                          </thead>
                          <tbody>
                            {Object.entries(this.state.itemDataRevenue)
                              .sort((a, b) => a[1].sales - b[1].sales)
                              .slice(0, 100)
                              .map(([itemCode, itemDetails]) => (
                                <tr key={itemCode}>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    {itemDetails.description}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    {this.formatSales(itemDetails.sales)}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    {itemDetails.quantity}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="text-center align-items-center mt-4">
                    <img
                      src="./choosefirst.png"
                      style={{ height: "200px", width: "200px" }}
                    />
                    <h4>To proceed, please select a date first</h4>
                  </div>
                )}

                <div className="d-flex justify-content-between align-items-center">
                  <h1 className="fw-bold mt-3 text-capitalize text-black">
                    Top <span style={{ color: "#83c14a" }}>Menu Items</span> by{" "}
                    <span style={{ color: "#D1DE48" }}>Sold Quantity</span>
                  </h1>
                  <Export
                    exportPdf={() =>
                      this.printChart("chart-container-top-quantity")
                    }
                    exportCsv={() => this.exportToCSV("quantity", true, 100)}
                  />
                </div>
                {this.state.objects?.length > 0 ? (
                  <div
                    id="chart-container-top-quantity"
                    className="chart-container"
                  >
                    <div className="card">
                      <Bar data={chartDataTopQuantity} />
                    </div>
                    <div className="print-only-content">
                      <h2>Report Details</h2>
                      <div className="d-flex justify-content-between align-items-center print-only-content">
                        <h3>Brand: Angels Pizza</h3>
                        <h4 className="mx-auto"></h4>
                        <h3>Store: {this.state.storess}</h3>
                        <h3>
                          Date Range:{" "}
                          <span>
                            {this.state.range &&
                              this.state.range?.tran_date["$gte"].split("T")[0]}
                          </span>{" "}
                          <span>
                            {this.state.range &&
                              this.state.range?.tran_date["$lte"].split("T")[0]}
                          </span>
                        </h3>
                        <h3>Top Menu Items by Sold Quantity</h3>
                      </div>
                      <div className="print-only" style={{ fontSize: "20px" }}>
                        <table className="table">
                          <thead>
                            <tr>
                              <th
                                style={{
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                }}
                              >
                                Menu Item
                              </th>
                              <th
                                style={{
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                }}
                              >
                                Sold Quantity
                              </th>
                              <th className="ms-5">Revenue</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.entries(this.state.itemDataQuantity)
                              .sort((a, b) => b[1].quantity - a[1].quantity)
                              .slice(0, 100)
                              .map(([itemCode, itemDetails]) => (
                                <tr key={itemCode}>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    {itemDetails.description}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    {itemDetails.quantity}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    {this.formatSales(itemDetails.sales)}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="text-center align-items-center mt-4">
                    <img
                      src="./choosefirst.png"
                      style={{ height: "200px", width: "200px" }}
                    />
                    <h4>To proceed, please select a date first</h4>
                  </div>
                )}

                <div className="d-flex justify-content-between align-items-center">
                  <h1 className="fw-bold mt-3 text-capitalize text-black">
                    Bottom <span style={{ color: "#83c14a" }}>Menu Items</span>{" "}
                    by <span style={{ color: "#D1DE48" }}>Sold Quantity</span>
                  </h1>
                  <Export
                    exportPdf={() =>
                      this.printChart("chart-container-bottom-quantity")
                    }
                    exportCsv={() => this.exportToCSV("quantity", false, 100)}
                  />
                </div>
                {this.state.objects?.length > 0 ? (
                  <div
                    id="chart-container-bottom-quantity"
                    className="chart-container"
                  >
                    <div className="card">
                      <Bar data={chartDataBottomQuantity} />
                    </div>
                    <div className="print-only-content">
                      <h2>Report Details</h2>
                      <div className="d-flex justify-content-between align-items-center print-only-content">
                        <h3>Brand: Angels Pizza</h3>
                        <h4 className="mx-auto"></h4>
                        <h3>Store: {this.state.storess}</h3>
                        <h3>
                          Date Range:{" "}
                          <span>
                            {this.state.range &&
                              this.state.range?.tran_date["$gte"].split("T")[0]}
                          </span>{" "}
                          <span>
                            {this.state.range &&
                              this.state.range?.tran_date["$lte"].split("T")[0]}
                          </span>
                        </h3>
                        <h3>Bottom Menu Items by Sold Quantity</h3>
                      </div>
                      <div className="print-only" style={{ fontSize: "20px" }}>
                        <table className="table text-center">
                          <thead>
                            <tr className="d-flex">
                              <th>Menu Item</th>
                              <th className="ms-5">Sold Quantity</th>
                              <th className="ms-5">Revenue</th>
                            </tr>{" "}
                          </thead>
                          <tbody>
                            {Object.entries(this.state.itemDataQuantity)
                              .sort((a, b) => a[1].quantity - b[1].quantity)
                              .slice(0, 100)
                              .map(([itemCode, itemDetails]) => (
                                <tr key={itemCode}>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    {itemDetails.description}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    {itemDetails.quantity}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    {this.formatSales(itemDetails.sales)}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="text-center align-items-center mt-4">
                    <img
                      src="./choosefirst.png"
                      style={{ height: "200px", width: "200px" }}
                    />
                    <h4>To proceed, please select a date firstsss</h4>
                  </div>
                )}
              </>
            ) : this.state.range && this.state.storess ? (
              // <div className="text-center align-items-center mt-4">
              //   <img
              //     src="./choosefirst.png"
              //     style={{ height: "200px", width: "200px" }}
              //   />
              //   <h4>To proceed, please select a date firstsss</h4>
              // </div>
              <div className="text-center mt-5">
                <Progress />
                <h6>Processing... Please wait.</h6>
              </div>
            ) : (
              <>
                <div className="row mt-1 g-3">
                  {schemaDashboard.map((object, index) => {
                    const { value, percentage } =
                      this.getValueForDashboardObject(object, objects);

                    const navigationTarget = object.navigationTarget;
                    return (
                      <div key={index} className="col-6 col-md-3">
                        <Count
                          collection={object.collection}
                          icon={object.icon}
                          where={object.where}
                          val={value}
                          label={object.label}
                          labelAction={object.labelAction}
                          barangayValue={this.state.barangayValue}
                          object={object}
                          percentage={percentage}
                          bg={object.bg}
                          onClick={() =>
                            this.handleCountClick(navigationTarget)
                          }
                        />
                      </div>
                    );
                  })}
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <h1 className="fw-bold mt-3 text-capitalize text-black">
                    Top <span style={{ color: "#83c14a" }}>Menu Items</span> by{" "}
                    <span style={{ color: "#D1DE48" }}>Revenue</span>
                  </h1>
                  <Export
                    exportPdf={() =>
                      this.printChart("chart-container-top-revenue")
                    }
                    exportCsv={() => this.exportToCSV("revenue", true, 100)}
                  />
                </div>
                {this.state.objects?.length > 0 ? (
                  <div
                    id="chart-container-top-revenue"
                    className="chart-container"
                  >
                    <div className="card">
                      <Bar data={chartDataTopRevenue} />
                    </div>
                    <div className="print-only-content">
                      <h2>Report Details</h2>
                      <div className="d-flex justify-content-between align-items-center print-only-content">
                        <h3>Brand: Angels Pizza</h3>
                        <h4 className="mx-auto"></h4>
                        <h3>Store: {this.state.storess}</h3>
                        <h3>
                          Date Range:{" "}
                          <span>
                            {this.state.range &&
                              this.state.range?.tran_date["$gte"].split("T")[0]}
                          </span>{" "}
                          <span>
                            {this.state.range &&
                              this.state.range?.tran_date["$lte"].split("T")[0]}
                          </span>
                        </h3>
                        <h3>Top Menu Items By Revenue</h3>
                      </div>
                      <div className="print-only" style={{ fontSize: "20px" }}>
                        <table className="table text-center">
                          <thead>
                            <tr className="d-flex">
                              <th>Menu Item</th>
                              <th className="ms-5">Revenue</th>
                              <th className="ms-5">Quantity</th>
                            </tr>{" "}
                          </thead>
                          <tbody>
                            {Object.entries(this.state.itemDataRevenue)
                              .sort((a, b) => b[1].sales - a[1].sales)
                              .slice(0, 100)
                              .map(([itemCode, itemDetails]) => (
                                <tr key={itemCode}>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    {itemDetails.description}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    {this.formatSales(itemDetails.sales)}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    {itemDetails.quantity}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="text-center align-items-center mt-4">
                    <img
                      src="./choosefirst.png"
                      style={{ height: "200px", width: "200px" }}
                    />
                    <h4>To proceed, please select a date first</h4>
                  </div>
                )}

                <div className="d-flex justify-content-between align-items-center">
                  <h1 className="fw-bold mt-3 text-capitalize text-black">
                    Bottom <span style={{ color: "#83c14a" }}>Menu Items</span>{" "}
                    by <span style={{ color: "#D1DE48" }}>Revenue</span>
                  </h1>
                  <Export
                    exportPdf={() =>
                      this.printChart("chart-container-bottom-revenue")
                    }
                    exportCsv={() => this.exportToCSV("revenue", false, 100)}
                  />
                </div>
                {this.state.objects?.length > 0 ? (
                  <div
                    id="chart-container-bottom-revenue"
                    className="chart-container"
                  >
                    <div className="card">
                      <Bar data={chartDataBottomRevenue} />
                    </div>
                    <div className="print-only-content">
                      <h2>Report Details</h2>
                      <div className="d-flex justify-content-between align-items-center print-only-content">
                        <h3>Brand: Angels Pizza</h3>
                        <h4 className="mx-auto"></h4>
                        <h3>Store: {this.state.storess}</h3>
                        <h3>
                          Date Range:{" "}
                          <span>
                            {this.state.range &&
                              this.state.range?.tran_date["$gte"].split("T")[0]}
                          </span>{" "}
                          <span>
                            {this.state.range &&
                              this.state.range?.tran_date["$lte"].split("T")[0]}
                          </span>
                        </h3>
                        <h3>Bottom Menu Items By Revenue</h3>
                      </div>
                      <div className="print-only" style={{ fontSize: "20px" }}>
                        <table className="table text-center">
                          <thead>
                            <tr className="d-flex">
                              <th>Menu Item</th>
                              <th className="ms-5">Revenue</th>
                              <th className="ms-5">Quantity</th>
                            </tr>{" "}
                          </thead>
                          <tbody>
                            {Object.entries(this.state.itemDataRevenue)
                              .sort((a, b) => a[1].sales - b[1].sales)
                              .slice(0, 100)
                              .map(([itemCode, itemDetails]) => (
                                <tr key={itemCode}>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    {itemDetails.description}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    {this.formatSales(itemDetails.sales)}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    {itemDetails.quantity}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="text-center align-items-center mt-4">
                    <img
                      src="./choosefirst.png"
                      style={{ height: "200px", width: "200px" }}
                    />
                    <h4>To proceed, please select a date first</h4>
                  </div>
                )}

                <div className="d-flex justify-content-between align-items-center">
                  <h1 className="fw-bold mt-3 text-capitalize text-black">
                    Top <span style={{ color: "#83c14a" }}>Menu Items</span> by{" "}
                    <span style={{ color: "#D1DE48" }}>Sold Quantity</span>
                  </h1>
                  <Export
                    exportPdf={() =>
                      this.printChart("chart-container-top-quantity")
                    }
                    exportCsv={() => this.exportToCSV("quantity", true, 100)}
                  />
                </div>
                {this.state.objects?.length > 0 ? (
                  <div
                    id="chart-container-top-quantity"
                    className="chart-container"
                  >
                    <div className="card">
                      <Bar data={chartDataTopQuantity} />
                    </div>
                    <div className="print-only-content">
                      <h2>Report Details</h2>
                      <div className="d-flex justify-content-between align-items-center print-only-content">
                        <h3>Brand: Angels Pizza</h3>
                        <h4 className="mx-auto"></h4>
                        <h3>Store: {this.state.storess}</h3>
                        <h3>
                          Date Range:{" "}
                          <span>
                            {this.state.range &&
                              this.state.range?.tran_date["$gte"].split("T")[0]}
                          </span>{" "}
                          <span>
                            {this.state.range &&
                              this.state.range?.tran_date["$lte"].split("T")[0]}
                          </span>
                        </h3>
                        <h3>Top Menu Items by Sold Quantity</h3>
                      </div>
                      <div className="print-only" style={{ fontSize: "20px" }}>
                        <table className="table">
                          <thead>
                            <tr>
                              <th
                                style={{
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                }}
                              >
                                Menu Item
                              </th>
                              <th
                                style={{
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                }}
                              >
                                Sold Quantity
                              </th>
                              <th className="ms-5">Revenue</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.entries(this.state.itemDataQuantity)
                              .sort((a, b) => b[1].quantity - a[1].quantity)
                              .slice(0, 100)
                              .map(([itemCode, itemDetails]) => (
                                <tr key={itemCode}>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    {itemDetails.description}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    {itemDetails.quantity}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    {this.formatSales(itemDetails.sales)}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="text-center align-items-center mt-4">
                    <img
                      src="./choosefirst.png"
                      style={{ height: "200px", width: "200px" }}
                    />
                    <h4>To proceed, please select a date first</h4>
                  </div>
                )}

                <div className="d-flex justify-content-between align-items-center">
                  <h1 className="fw-bold mt-3 text-capitalize text-black">
                    Bottom <span style={{ color: "#83c14a" }}>Menu Items</span>{" "}
                    by <span style={{ color: "#D1DE48" }}>Sold Quantity</span>
                  </h1>
                  <Export
                    exportPdf={() =>
                      this.printChart("chart-container-bottom-quantity")
                    }
                    exportCsv={() => this.exportToCSV("quantity", false, 100)}
                  />
                </div>
                {this.state.objects?.length > 0 ? (
                  <div
                    id="chart-container-bottom-quantity"
                    className="chart-container"
                  >
                    <div className="card">
                      <Bar data={chartDataBottomQuantity} />
                    </div>
                    <div className="print-only-content">
                      <h2>Report Details</h2>
                      <div className="d-flex justify-content-between align-items-center print-only-content">
                        <h3>Brand: Angels Pizza</h3>
                        <h4 className="mx-auto"></h4>
                        <h3>Store: {this.state.storess}</h3>
                        <h3>
                          Date Range:{" "}
                          <span>
                            {this.state.range &&
                              this.state.range?.tran_date["$gte"].split("T")[0]}
                          </span>{" "}
                          <span>
                            {this.state.range &&
                              this.state.range?.tran_date["$lte"].split("T")[0]}
                          </span>
                        </h3>
                        <h3>Bottom Menu Items by Sold Quantity</h3>
                      </div>
                      <div className="print-only" style={{ fontSize: "20px" }}>
                        <table className="table text-center">
                          <thead>
                            <tr className="d-flex">
                              <th>Menu Item</th>
                              <th className="ms-5">Sold Quantity</th>
                              <th className="ms-5">Revenue</th>
                            </tr>{" "}
                          </thead>
                          <tbody>
                            {Object.entries(this.state.itemDataQuantity)
                              .sort((a, b) => a[1].quantity - b[1].quantity)
                              .slice(0, 100)
                              .map(([itemCode, itemDetails]) => (
                                <tr key={itemCode}>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    {itemDetails.description}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    {itemDetails.quantity}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    {this.formatSales(itemDetails.sales)}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="text-center align-items-center mt-4">
                    <img
                      src="./choosefirst.png"
                      style={{ height: "200px", width: "200px" }}
                    />
                    <h4>To proceed, please select a date firstsss</h4>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(ProductsPage);

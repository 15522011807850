import BaseListPresenter from "../../base/BaseListPresenter";
import { getCurrentUserUseCase } from "../../usecases/user";
import {
  format,
  startOfDay,
  addHours,
  addMinutes,
  addDays,
  parseISO,
} from "date-fns";

class ReportPresenter extends BaseListPresenter {
  constructor(view, findObjectUseCase, countObjectUseCase, exportCSVUseCase) {
    super(view, findObjectUseCase, countObjectUseCase, exportCSVUseCase);

    this.exportCSVUseCase = exportCSVUseCase;
  }
  init() {
    this.limit = 2000000;
    this.where = {};
    this.search = {};
    this.filter = {};
    this.filterDate = {};
    this.filterType = {};
    this.filterStore = {};
    this.include = ["all"];
    this.keys = undefined; // if keys are specified, only those keys will be returned
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.reset();
  }

  createQuery() {
    const skip = (this.current - 1) * this.limit;
    const query = {
      limit: this.limit,
      skip: skip,
      where: {
        ...this.where,
        ...this.search,
        ...this.filter,
        ...this.filterDate,
        ...this.filterType,
      },
      include: this.include,
    };
    if (this.sort) {
      query.sort = this.sort;
    }
    const keys = this.keys || this.view.getKeys() || [];
    if (keys.length > 0) {
      query.keys = keys;
    }
    return query;
  }

  async findObjects() {
    // Fetch the current user and store information
    try {
      const user = await getCurrentUserUseCase().execute();
      const storeQuery = {
        include: ["all"],
        where: { email: user.email },
      };
      this.store = await this.findObjectUseCase.execute("users", storeQuery);
      // const store = this.store[0].storeAnalytic;
      const store = this.store[0].storeAnalytic.sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0; // names must be equal
      });
      this.view.setStore(store);
    } catch (error) {
      console.error("Error fetching user or store data:", error);
      return; // Exit if there's an error fetching user or store data
    }

    // Prepare the query for finding objects
    const skip = (this.current - 1) * this.limit;
    const storeCodes = this.store?.flatMap((storeItem) =>
      storeItem.storeAnalytic?.map((analytic) => analytic?.name)
    );

    let a;

    const query = {
      limit: 2000000,
      skip: skip,
      where: {},
      where: {
        store_code:
          this.filterStore === "AllStore" ? storeCodes : this.filterStore,
        ...this.filterDate,
      },
      include: ["all"],
      sort: this.sort,
      keys: [
        "store_code",
        "tran_date",
        "total",
        "total_taxfree",
        "total_tax",
        "discount_amount",
        "createdAt",
        "cust_name",
        "tran_type",
        "paymenttype",
        "cust_count",
        "total_pretax",
        "status",
        // "items",
      ],
    };

    // Execute the query and update the view
    try {
      this.showProgress();
      this.findObjectUseCase.abort(); // Abort any previous use case execution
      const objects = await this.findObjectUseCase.execute(
        this.view.getCollectionName(),
        query
      );
      // this.objects = this.objects.concat(objects);
      // this.view.setTotal(this.objects.length);
      // this.view.setObjects(this.objects);
      if (Object.keys(this.filterDate).length === 0) {
        // No filterDate applied, skip updating objects
        console.log("sample");
      } else {
        // filterDate is applied, proceed with updating objects
        this.objects = this.objects.concat(objects);
        // console.log("getget", this.objects.length);
        this.view.setTotal(this.objects.length);
        this.view.setObjects(this.objects);
      }
    } catch (error) {
      console.error("Error fetching objects:", error);
      this.view.showError(error);
    } finally {
      this.hideProgress();
      this.progress = false;
    }
  }

  async aggregateData() {
    const objects = this.objects;
    const aggregatedData = {};

    objects
      .filter((obj) => obj.status === "C")
      .forEach((obj) => {
        const storeCode = obj.store_code;
        // console.log("storecode", storeCode);
        const tranType = this.view.getTranType(obj.tran_type);

        if (!aggregatedData[storeCode]) {
          aggregatedData[storeCode] = {};
        }

        if (!aggregatedData[storeCode][tranType]) {
          aggregatedData[storeCode][tranType] = { tc: 0, netSale: 0 };
        }

        const grossSale = parseFloat(obj.total);
        // +
        // parseFloat(obj.discount_amount) +
        // parseFloat(obj.total_tax);
        const netSale = grossSale;
        // - parseFloat(obj.discount_amount);

        aggregatedData[storeCode][tranType].tc += 1;
        aggregatedData[storeCode][tranType].netSale += netSale;
      });

    this.view.setState({ aggregatedData });
  }

  async getObjects() {
    this.reset();

    await this.findObjects();
    await this.aggregateData();
  }

  // onChangeDate(where) {
  //   this.filterDate = where;
  //   console.log("date", this.filterDate);
  //   this.getObjects();
  // }

  // onChangeDate(where) {
  //   const { tran_date } = where;
  //   console.log("tran", tran_date);

  //   if (tran_date && tran_date.$gte && tran_date.$lte) {
  //     const startDate = addHours(startOfDay(parseISO(tran_date.$gte)), 5);
  //     const endDate = addMinutes(addHours(addDays(startDate, 1), -1), 59);

  //     const formattedStartDate = format(startDate, "yyyy-MM-dd HH:mm:ss.SSS");
  //     const formattedEndDate = format(endDate, "yyyy-MM-dd HH:mm:ss.SSS");

  //     this.filterDate = {
  //       tran_date: {
  //         $gte: formattedStartDate,
  //         $lte: formattedEndDate,
  //       },
  //     };
  //   }

  //   console.log("date", this.filterDate);
  //   this.getObjects();
  // }

  // onChangeDate(where) {
  //   const { tran_date } = where;
  //   console.log("tran", tran_date);

  //   if (tran_date && tran_date.$gte && tran_date.$lte) {
  //     const startDate = addHours(startOfDay(parseISO(tran_date.$gte)), 5);
  //     const endDate = addMinutes(
  //       addHours(addDays(startOfDay(parseISO(tran_date.$lte)), 1), -1),
  //       59
  //     ); // Correct the end date

  //     const formattedStartDate = format(startDate, "yyyy-MM-dd HH:mm:ss.SSS");
  //     const formattedEndDate = format(endDate, "yyyy-MM-dd HH:mm:ss.SSS");

  //     this.filterDate = {
  //       tran_date: {
  //         $gte: formattedStartDate,
  //         $lte: formattedEndDate,
  //       },
  //     };
  //   }

  //   console.log("date", this.filterDate);
  //   this.getObjects();
  // }

  onChangeDate(where) {
    const { tran_date } = where;
    // console.log("tran", tran_date);

    if (tran_date && tran_date.$gte && tran_date.$lte) {
      const startDate = addHours(startOfDay(parseISO(tran_date.$gte)), 5);
      const endDate = addMinutes(
        addHours(addDays(startOfDay(parseISO(tran_date.$lte)), 0), 4),
        59
      );

      const formattedStartDate = format(startDate, "yyyy-MM-dd HH:mm:ss.SSS");
      const formattedEndDate = format(endDate, "yyyy-MM-dd HH:mm:ss.SSS");

      this.filterDate = {
        tran_date: {
          $gte: formattedStartDate,
          $lte: formattedEndDate,
        },
      };
    }

    // console.log("date", this.filterDate);
    this.getObjects();
  }
  onChangeStore(where) {
    this.filterStore = where;
    this.getObjects();
  }

  onChangeType(where) {
    this.filterType = { tran_type: where };
    // console.log("date", this.filterType);
    this.getObjects();
  }

  calculateSums(objects) {
    const sumReducer = (acc, object, key) => acc + Number(object[key] || 0);
    const sum_total = objects?.reduce(
      (acc, obj) => sumReducer(acc, obj, "total"),
      0
    );
    const sum_discount = objects?.reduce(
      (acc, obj) => sumReducer(acc, obj, "discount_amount"),
      0
    );
    const sum_taxes = objects?.reduce(
      (acc, obj) => sumReducer(acc, obj, "total_tax"),
      0
    );

    return {
      sum_total,
      sum_discount,
      sum_taxes,
      grossSum: sum_total + sum_discount,
      grossSales: sum_total + sum_discount - sum_taxes,
    };
  }

  calculateMakatiInfo() {
    const transactions = this.objects;
    const makatiTrans = transactions
      ?.map((trans) => trans)
      .filter((trans) => trans.store_code === "ANGELSPIZZA_RETIRO");
    console.log("makati", makatiTrans);
    const prevGross = 10000;
    const { grossSales } = this.calculateSums(this.objects);
    return {
      value: makatiTrans?.length,
      percentage: this.calculatePercentage(grossSales, prevGross),
    };
  }

  calculateRetiroInfo() {
    const prevTransaction = 5;
    const totalTransaction = this.objects;
    return {
      value: totalTransaction?.length,
      percentage: this.calculatePercentage(
        totalTransaction?.length,
        prevTransaction
      ),
    };
  }

  calculateTotalGuestInfo() {
    const prevCust = 6;
    const custCount = this.countCustomers();
    return {
      value: custCount,
      percentage: this.calculatePercentage(custCount, prevCust),
    };
  }

  calculateDiscountCost() {
    const prevCust = 6;
    const discountSum = this.objects
      ?.map((cust) => Number(cust.discount_amount))
      .reduce((acc, prev) => acc + prev, 0);
    return {
      value: discountSum,
      percentage: this.calculatePercentage(discountSum, prevCust),
    };
  }

  calculatePercentage(current, previous) {
    if (previous > 0 && current !== undefined) {
      return parseFloat(((current - previous) / previous) * 100).toFixed(2);
    }
    return 0;
  }

  countCustomers() {
    return this.objects
      ?.filter((cus) => cus.cust_name !== "-")
      .map((cust) => cust.cust_name).length;
  }

  onClickExport(objects) {
    // console.log("flat", objects);
    const collection = this.view.getCollectionName();
    const schema = this.view.getSchema(collection);
    // console.log("schema", schema);
    // console.log("collec", collection);
    this.exportCSVUseCase.execute(schema, this.objects, collection).then(() => {
      //hide progress
    });
  }
}

export default ReportPresenter;

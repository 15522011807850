import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import BaseListPage from "../../../../base/BaseListPage";
import { findObjectUseCase } from "../../../../usecases/object";
import AverageSalesTimePresenter from "./AverageSalesTimePresenter.js";
import Export from "../../../../components/Export/index.js";
import printChart from "../../../../PrintChart.js";
import { Progress } from "nq-component";

class AverageSalesTimePage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new AverageSalesTimePresenter(this, findObjectUseCase());

    this.state = {
      objects: [],
      selectedDate: new Date().toISOString().split("T")[0],
      selectedStoreCode: "",
      averageSalesPerHour: [],
      storess: "",
      store: [],
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleStoreCodeChange = this.handleStoreCodeChange.bind(this);
  }

  setStore(store) {
    this.setState({ store });
  }

  onChangeStore(where) {
    this.setState({ storess: where });
    this.presenter.onChangeStore(where);
  }

  getUniqueStoreCodes() {
    const storeCodes = this.state.objects.map((obj) =>
      obj.store_code.replace("ANGELSPIZZA_", "")
    );

    return [...new Set(storeCodes)]; // Remove duplicates
  }

  filterTransactions(transactions) {
    const selectedDate = new Date(this.state.selectedDate);
    selectedDate.setHours(5, 0, 0, 0);

    const endDate = new Date(selectedDate);
    endDate.setDate(endDate.getDate() + 1);
    endDate.setHours(4, 59, 59, 999);

    return transactions
      .filter((status) => status.status === "C")
      .filter((transaction) => {
        const transactionDate = new Date(transaction.tran_date);

        const selectedDateMatch =
          transactionDate >= selectedDate && transactionDate <= endDate;
        const storeCodeMatch = this.state.selectedStoreCode
          ? transaction.store_code.replace("ANGELSPIZZA_", "") ===
            this.state.selectedStoreCode
          : true;

        return selectedDateMatch && storeCodeMatch;
      });
  }

  handleDateChange(event) {
    const selectedDate = event.target.value;
    const formattedDate = this.formatDate(selectedDate);
    this.setState({ selectedDate: event.target.value });
    // this.setState({ selectedDate });
    this.presenter.onChangeDate(formattedDate);
  }

  formatDate(dateString) {
    const date = new Date(dateString);

    // Start date (same day at 05:00:00.000)
    const startTime = new Date(date);
    startTime.setHours(5, 0, 0, 0);
    const formattedStartTime = this.formatDateToCustom(startTime);

    // End date (next day at 04:59:00.000)
    const endTime = new Date(date);
    endTime.setDate(endTime.getDate() + 1); // Move to the next day
    endTime.setHours(4, 59, 0, 0);
    const formattedEndTime = this.formatDateToCustom(endTime);

    return {
      tran_date: {
        $gte: formattedStartTime,
        $lte: formattedEndTime,
      },
    };
  }

  formatDateToCustom(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const milliseconds = String(date.getMilliseconds()).padStart(3, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
  }

  handleStoreCodeChange(event) {
    this.setState({ selectedStoreCode: event.target.value }); // Update the state when store code changes
  }

  // calculateAverageSales(transactions) {
  //   const totalSales = new Array(24).fill(0); // Array for 23 hours (5 AM to 4 AM)
  //   const hourCounts = new Array(24).fill(0);

  //   transactions.forEach((transaction) => {
  //     const date = new Date(transaction.tran_date);
  //     const hour = date.getHours();

  //     // Adjust the index to start from 5 AM
  //     const index = hour < 5 ? hour + 19 : hour - 5; // Hours 0-4 become 19-22, hours 5-23 stay as 0-18

  //     const grossSales =
  //       parseFloat(transaction.total) +
  //       parseFloat(transaction.discount_amount) +
  //       parseFloat(transaction.total_tax);
  //     const sales = grossSales - parseFloat(transaction.discount_amount);

  //     totalSales[index] += sales;
  //     hourCounts[index]++;
  //   });

  //   // Calculate the average sales for each hour
  //   const averageSales = totalSales.map((total, index) => {
  //     return hourCounts[index] > 0 ? total / hourCounts[index] : 0;
  //   });

  //   return { averageSales };
  // }
  calculateAverageSales(transactions) {
    const totalSales = new Array(24).fill(0); // Array for 23 hours (5 AM to 4 AM)
    const transactionCounts = new Array(24).fill(0);

    transactions.forEach((transaction) => {
      const date = new Date(transaction.tran_date);
      const hour = date.getHours();

      // Adjust the index to start from 5 AM
      const index = hour < 5 ? hour + 19 : hour - 5; // Hours 0-4 become 19-22, hours 5-23 stay as 0-18

      const grossSales = parseFloat(transaction.total);
      const sales = grossSales - parseFloat(transaction.total_tax);

      totalSales[index] += sales;
      transactionCounts[index]++;
      // console.log(
      //   `Transaction at hour ${hour}: index ${index}, count ${transactionCounts[index]}`
      // );
    });

    // console.log("Transaction counts for each hour:", transactionCounts);

    // Calculate the average sales for each hour
    const averageSales = totalSales.map((total, index) => {
      return transactionCounts[index] > 0
        ? total / transactionCounts[index]
        : 0;
    });

    return { averageSales };
  }

  getChartData() {
    const filteredTransactions = this.filterTransactions(this.state.objects);
    // console.warn("TOTAL: ", filteredTransactions);
    const { averageSales } = this.calculateAverageSales(filteredTransactions);
    if (
      JSON.stringify(this.state.averageSalesPerHour) !==
      JSON.stringify(averageSales)
    ) {
      this.setState({ averageSalesPerHour: averageSales });
    }

    const labels = Array.from({ length: 24 }, (_, i) => {
      const hour = (5 + i) % 24;
      return `${hour}:00`;
    });

    const formattedAverageSales = averageSales.map((sales) => sales.toFixed(2));

    return {
      labels: labels, // 0 to 23, labeled as hours
      datasets: [
        {
          label: "Average Sales",
          data: formattedAverageSales,
          backgroundColor: "#006096",
          borderColor: "#006096",
          borderWidth: 1,
        },
      ],
    };
  }

  getChartOptions() {
    return {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };
  }

  setObjects(objects) {
    this.setState({ objects });
  }

  getCollectionName() {
    return "transactions";
  }

  convertToCSV(objArray) {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str =
      `${Object.keys(array[0])
        .map((value) => `"${value}"`)
        .join(",")}` + "\r\n";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line !== "") line += ",";
        line += `"${array[i][index]}"`;
      }
      str += line + "\r\n";
    }
    return str;
  }

  exportToCSV = () => {
    const {
      selectedBrand,
      selectedStoreCode,
      selectedDate,
      averageSalesPerHour,
    } = this.state;

    const csvData = averageSalesPerHour.map((sales, index) => {
      const hour = (5 + index) % 24; // Adjust according to your chart's starting hour
      return {
        brand: "Angels Pizza",
        store: selectedStoreCode || "All Stores",
        date: this.state.selectedDate,
        time: `${hour}:00`,
        averageSales: sales.toFixed(2),
      };
    });

    const csvString = this.convertToCSV(csvData);
    const a = document.createElement("a");
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = `average-sales-time.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  render() {
    const chartData = this.getChartData();
    const chartOptions = this.getChartOptions();
    const uniqueStoreCodes = this.getUniqueStoreCodes();
    const printId = "chart-container2";
    const formatSales = (sales) => {
      return sales.toLocaleString("en-US", {
        style: "currency",
        currency: "PHP",
      });
    };

    return (
      <>
        <div className="d-flex justify-content-between align-items-center mb-1">
          <h2 className="fw-bold text-capitalize mt-4">
            <span style={{ color: "darkcolor" }}>Average</span>
            <span style={{ color: "#83c14a" }}> Sales</span>
            <span style={{ color: "darkcolor" }}> vs. </span>
            <span style={{ color: "#83c14a" }}>Hour of Today</span>
          </h2>
          <Export
            exportCsv={() => this.exportToCSV()}
            exportPdf={() => printChart(printId)}
          />
        </div>
        <div className="row mb-2">
          <div className="col-sm-2">
            <input
              className="form-control"
              type="date"
              // value={this.state.selectedDate}
              onChange={this.handleDateChange}
            />
          </div>
          <div className="col-sm-3">
            <select
              className="form-select"
              // value={this.state.selectedStoreCode}
              onChange={(e) => this.onChangeStore(e.target.value)}
            >
              <option value="">Select Store</option>
              <option value="AllStore">All Store</option>
              {this.state.store.map((code, index) => (
                <option key={index} value={code.name}>
                  {code.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {this.state.objects && this.state.objects.length > 0 ? (
          <div ref={this.chartRef1} id="chart-container2">
            <div className="card">
              <Bar data={chartData} options={chartOptions} />
            </div>
            <div className="print-only-content">
              <h2>Report Details</h2>
              <div className=" print-only-content">
                <div className="">
                  <h3>Brand: Angels Pizza</h3>
                  <h3>Store: {this.state.storess}</h3>
                  <h3>Date: {this.state.selectedDate}</h3>Í
                </div>
              </div>
              <div className="" style={{ fontSize: "20px" }}>
                <table className="table text-center">
                  <thead>
                    <tr className="d-flex">
                      <th>Month</th>
                      <th className="ms-5">Sales</th>{" "}
                    </tr>
                  </thead>
                  Í
                  <tbody>
                    {chartData.labels.map((label, index) => (
                      <tr className="d-flex" key={label}>
                        <td>{label}</td>
                        <td>
                          {formatSales(chartData.datasets[0].data[index])}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : this.state.storess && this.state?.selectedDate ? (
          <div className="text-center mt-5">
            <Progress />
            <h6>Processing... Please wait.</h6>
          </div>
        ) : (
          <div className="text-center align-items-center mt-4">
            <img
              src="./choosefirst.png"
              style={{ height: "200px", width: "200px" }}
            />
            <h4>To proceed, please select a store first</h4>
          </div>
        )}
      </>
    );
  }
}

export default AverageSalesTimePage;
